import axios from 'axios';
import { BigNumber, utils } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAccount } from 'wagmi';
import { Icons } from '../../assets/icons';
import { getEthersWeb3Provider } from '../../ducks/ethers/web3/selectors';
import { setConfigLM } from '../../ducks/project/action';
import { getProjectConfig } from '../../ducks/project/selectors';
import { addSnackbar } from '../../ducks/snackbar/action';
import { createDuration, DurationUnits } from '../../sdk/helpers/duration';
import { getNetworkConfig, getNetworkName } from '../../sdk/helpers/network';
import { LiquidityMiningCampaignFactory } from '../../sdk/staking-v1/LiquidityMiningCampaignFactory';
import { LockScheme } from '../../sdk/staking-v1/LockScheme';
import { LMInterface } from '../../types/dto/Project.dto';
import { getSignature } from '../../utils/getSignature';
import { Button } from '../Button';
import { DurationInput } from '../DurationInput';
import { LMReward, LockingPeriod, Pool } from '../LiquidityMiningPool';
import { Modal } from '../Modal';
import { TextField } from '../TextField';

export const PoolLockScheme: React.FC<{
  pool: Pool;
  onClose: () => void;
}> = ({ pool, onClose }) => {
  const { address: wallet } = useAccount();

  const dispatch = useDispatch();

  const web3 = useSelector(getEthersWeb3Provider);
  const config = useSelector(getProjectConfig);
  const [network, setNetwork] = useState('');

  const [creatingLockScheme, setCreatingLockScheme] = useState(false);
  const [lockingPeriods, setLockingPeriods] = useState<Array<LockingPeriod>>([]);

  useEffect(() => {
    getNetworkName().then((network) => {
      setNetwork(network);
    });
  }, []);

  const addLockingSchemes = async (): Promise<void> => {
    if (!config.factory || !config.factory[network]) {
      dispatch(
        addSnackbar({ type: 'error', title: 'No factory', content: 'Please create a factory before proceeding.' }),
      );
      return;
    }
    if (!pool) {
      dispatch(
        addSnackbar({
          type: 'error',
          title: 'No pool loaded',
          content: 'Please create a pool before creating a locking scheme.',
        }),
      );
      return;
    }
    const networkConfig = await getNetworkConfig();

    const currentDate = Date.now();
    const periodInTime = (pool.end.getTime() - currentDate) / 1000;
    const periodInBLock = Math.floor(periodInTime / networkConfig.BLOCK_TIME);
    const calculatorAddress = networkConfig.CALCULATOR_ADDRESS;

    const factory = new LiquidityMiningCampaignFactory();
    await factory.load(config.factory[network]);

    let lockSchemeAddress = '';

    if (lockingPeriods.length > 0) {
      setCreatingLockScheme(true);
      const lockingSchemes = {} as {
        [key: string]: string;
      };

      for (const lockingPeriod of lockingPeriods) {
        const lockScheme = new LockScheme(calculatorAddress);

        if (lockingPeriod.noLock) {
          await lockScheme.deployNoLock(periodInBLock, pool.address);
        } else {
          const durationValue = lockingPeriod.duration['value'];
          const increaseDurationValue = lockingPeriod.increaseDuration['value'];

          const areValid =
            !isNaN(durationValue) &&
            durationValue > 0 &&
            !isNaN(increaseDurationValue) &&
            increaseDurationValue > 0 &&
            !isNaN(lockingPeriod.bonus) &&
            lockingPeriod.bonus >= 0;

          if (areValid) {
            await lockScheme.deploy(
              lockingPeriod.duration,
              lockingPeriod.increaseDuration,
              lockingPeriod.bonus,
              pool.address,
            );
          } else {
            setCreatingLockScheme(false);
            return;
          }
        }

        if (lockScheme.contract) {
          const lockSchemeKey = lockingPeriod.noLock
            ? 'NO-LOCK'
            : Object.values(lockingPeriod.duration)[0] + Object.keys(lockingPeriod.duration)[0][0].toUpperCase();
          lockSchemeAddress = lockScheme.contract.address;
          lockingSchemes[lockSchemeKey] = lockScheme.contract.address;
        }
      }

      await factory.addLockSchemes(Object.values(lockingSchemes), pool.address);

      const { signature, timestamp } = await getSignature(web3, dispatch);

      await axios.post(process.env.REACT_APP_API + '/project/config', {
        config: {
          lockScheme: {
            campaignAddress: pool.address,
            lockSchemeAddress,
          },
        },
        wallet,
        signature: signature,
        timestamp,
      });

      if (lockSchemeAddress !== '') {
        const newLMConfig: LMInterface[] = (config.campaignsLM || []).map((currCampaign) => {
          if (currCampaign.campaignAddress === pool.address) {
            return {
              ...currCampaign,
              lockSchemeAddress,
            };
          }
          return currCampaign;
        });

        dispatch(
          setConfigLM({
            value: newLMConfig,
          }),
        );
      }

      setCreatingLockScheme(false);
    }
    setLockingPeriods([]);
    onClose();
  };

  const showNoLockOption = (): boolean => {
    const foundSaved = (pool.savedLockingPeriods || []).find((i) => i.noLock);
    return !foundSaved;
  };

  const onlyOneLockingOption = (target: any): boolean => {
    const foundCurrent = lockingPeriods.find((i) => i.noLock);
    if (!!foundCurrent && !target.checked) {
      return false;
    }
    return !!foundCurrent;
  };

  return (
    <Modal title="Add locking schemes" onClose={onClose}>
      <div className="modal-text">
        Lock schemes give additional bonus to users that want to lock their staked liquidity. Ramp up period defines the
        amount of time the user has to still top up their tokens, while still having them count for the full lock scheme
        duration.
      </div>

      <div className="modal-form">
        {pool.savedLockingPeriods && pool.savedLockingPeriods.length > 0 && (
          <div className="pool-form-locking-container">
            <table className="pool-form-locking">
              <thead>
                <tr>
                  <th>Duration</th>
                  <th>Bonus (% of reward)</th>
                  <th>Ramp up period</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {(pool.savedLockingPeriods || []).map((period, periodIndex) => (
                  <tr key={periodIndex}>
                    {period.bonus === 0 ? (
                      <>
                        <td>
                          <div className="flex">
                            <p>No option</p>
                          </div>
                        </td>
                        <td>
                          <div className="flex">
                            <p>No option</p>
                          </div>
                        </td>
                        <td>
                          <div className="flex">
                            <p>No option</p>
                          </div>
                        </td>
                        <td>
                          <div className="flex">
                            <p>No lock</p>
                          </div>
                        </td>
                      </>
                    ) : (
                      <>
                        <td>
                          <div className="flex">
                            <DurationInput readOnly={true} value={period.duration} />
                          </div>
                        </td>
                        <td>
                          <TextField
                            value={period.bonus.toString()}
                            label=""
                            type="text"
                            icon="tokens"
                            readOnly={true}
                          />
                        </td>
                        <td>
                          <div className="flex">
                            <DurationInput value={period.increaseDuration} readOnly={true} />
                          </div>
                        </td>
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {lockingPeriods.length > 0 && (
          <div className="pool-form-locking-container">
            <table className="pool-form-locking">
              <thead>
                <tr>
                  <th>Duration</th>
                  <th>Bonus (% of reward)</th>
                  <th>Ramp up period</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {lockingPeriods.map((period, periodIndex) => (
                  <tr key={periodIndex}>
                    <td>
                      <div className="flex-center">
                        {!period.noLock && (
                          <DurationInput
                            value={period.duration}
                            readOnly={period.noLock}
                            onChange={(value): void =>
                              setLockingPeriods(
                                lockingPeriods.map((p, pi) => {
                                  if (pi === periodIndex) {
                                    p.duration = value;
                                  }
                                  return p;
                                }),
                              )
                            }
                          />
                        )}
                        {period.noLock && <p>No option</p>}
                      </div>
                    </td>
                    <td className="flex-center">
                      {!period.noLock && (
                        <TextField
                          value={period.bonus.toString()}
                          readOnly={period.noLock}
                          label=""
                          type="text"
                          icon="tokens"
                          onChange={(value): void => {
                            if (!isNaN(parseFloat(value))) {
                              setLockingPeriods(
                                lockingPeriods.map((p, pi) => {
                                  if (pi === periodIndex) {
                                    p.bonus = parseFloat(value);
                                  }
                                  return p;
                                }),
                              );
                            }
                          }}
                        />
                      )}
                      {period.noLock && <p>No option</p>}
                    </td>
                    <td>
                      <div className="flex-center">
                        {!period.noLock && (
                          <DurationInput
                            value={period.increaseDuration}
                            readOnly={period.noLock}
                            onChange={(value): void =>
                              setLockingPeriods(
                                lockingPeriods.map((p, pi) => {
                                  if (pi === periodIndex) {
                                    p.increaseDuration = value;
                                  }
                                  return p;
                                }),
                              )
                            }
                          />
                        )}
                        {period.noLock && <p>No option</p>}
                      </div>
                    </td>
                    <td>
                      {showNoLockOption() && (
                        <div className="no-lock">
                          No lock
                          <input
                            name="no-lock"
                            type="checkbox"
                            checked={period.noLock}
                            onChange={(event): void => {
                              if (onlyOneLockingOption(event.target)) {
                                dispatch(
                                  addSnackbar({
                                    type: 'error',
                                    title: 'Too many no-lock schemes',
                                    content: 'You can only set one no-lock scheme per pool.',
                                  }),
                                );
                                return;
                              }
                              const target = event.target;
                              const value = target.type === 'checkbox' ? target.checked : false;
                              setLockingPeriods(
                                lockingPeriods.map((p, pi) => {
                                  if (pi === periodIndex) {
                                    p.noLock = value;
                                  }
                                  return p;
                                }),
                              );
                            }}
                          />
                        </div>
                      )}
                    </td>
                    <td>
                      <img
                        className="pool-form-locking-remove"
                        src={Icons.bin}
                        onClick={(): void => setLockingPeriods(lockingPeriods.filter((p, pi) => pi !== periodIndex))}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        <div className="pool-form-locking-add flex flex-center">
          <Button
            color="primary"
            size="small"
            onClick={(): void =>
              setLockingPeriods([
                ...lockingPeriods,
                {
                  duration: createDuration(0, DurationUnits.days),
                  bonus: 0,
                  increaseDuration: createDuration(0, DurationUnits.days),
                  noLock: false,
                },
              ])
            }
            label="Add"
            icon="plus"
            iconposition="right"
          />
        </div>
      </div>

      {pool.campaignRewards
        .filter((reward) =>
          lockingPeriods
            .reduce(
              (a, v) =>
                a.add(
                  BigNumber.from(reward.totalRewards)
                    .mul(v.bonus || 0)
                    .div(100),
                ),
              BigNumber.from(0),
            )
            .gt(0),
        )
        .map((reward, rewardIndex) => (
          <p key={rewardIndex}>
            You need to have at least{' '}
            {utils.formatEther(
              lockingPeriods.reduce(
                (a, v) =>
                  a.add(
                    BigNumber.from(reward.totalRewards)
                      .mul(v.bonus || 0)
                      .div(100),
                  ),
                BigNumber.from(0),
              ),
            )}{' '}
            {reward.symbol} on top of the total reward
          </p>
        ))}

      <div className="modal-buttons">
        <Button
          color="primary"
          size="medium"
          onClick={addLockingSchemes}
          label="Update"
          iconposition="left"
          icon={creatingLockScheme ? 'reload' : 'update'}
          disabled={creatingLockScheme}
          spin={creatingLockScheme}
        />
      </div>
    </Modal>
  );
};
