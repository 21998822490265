export enum CoingeckoCurrencies {
  USD = 'usd',
}

export interface CoingeckoTokenInfo {
  id: string;
  symbol: string;
  name: string;
  asset_platform_id: string;
  image: {
    thumb: string;
    small: string;
    large: string;
  };
  detail_platforms: { [asset_platform_id: string]: { decimal_place: number; contract_address: string } };
  contract_address?: string;
  error?: string;
}

export const getCoingeckoTokenPrice = async (tokenId: string, currency: CoingeckoCurrencies): Promise<number> => {
  const { REACT_APP_COINGECKO_API } = process.env;
  const response = await fetch(`${REACT_APP_COINGECKO_API}/simple/price?ids=${tokenId}&vs_currencies=${currency}`);
  const data = await response.json();
  return data[tokenId][currency];
};

export const getCoingeckoTokenInfo = async (tokenId: string): Promise<CoingeckoTokenInfo> => {
  const { REACT_APP_COINGECKO_API } = process.env;
  const response = await fetch(`${REACT_APP_COINGECKO_API}/coins/${tokenId}`);
  return await response.json();
};

export const getCoingeckoTokenInfoByTokenAddress = async (
  network: string,
  tokenAddress: string,
): Promise<CoingeckoTokenInfo> => {
  const { REACT_APP_COINGECKO_API } = process.env;
  const response = await fetch(`${REACT_APP_COINGECKO_API}/coins/${network}/contract/${tokenAddress}`);
  const data = await response.json();
  return data;
};
