import artifacts from './RewardsPoolBase.json';
import { BigNumber, Contract } from 'ethers';
import { ContractLoader } from '../helpers/ContractLoader';
import { sendMultisig } from '../helpers/sendMultisig';
import { loadContract } from '../helpers/loadContract';
import { MultiSigWallet } from '@stichting-allianceblock-foundation/multisig-contracts/typechain/MultiSigWallet';
import { MULTISIG_DOES_NOT_EXIST } from '../constants/errors';

export class RewardsPoolBase {
  contract: Contract | null;
  loader: ContractLoader;

  constructor() {
    this.loader = new ContractLoader({
      name: 'RewardsPoolBaseV2',
      bytecode: artifacts.bytecode,
      abi: artifacts.abi,
    });

    this.contract = null;
  }

  async load(address: string): Promise<void> {
    this.contract = await this.loader.load(address);
  }

  async start(startTimestamp: number, endTimestamp: number, rewards: BigNumber[]): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const numSeconds = endTimestamp - startTimestamp;
    const rewardsPerSecond = rewards.map((reward) => reward.div(numSeconds).toString());

    return await sendMultisig(this.contract, 'start', [startTimestamp, endTimestamp, rewardsPerSecond]);
  }

  async cancel(): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await sendMultisig(this.contract, 'cancel', []);
  }

  async withdrawExcessRewards(recipient: string | undefined): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');
    if (recipient === undefined) throw new Error('Trying to use a undefined wallet');

    return await sendMultisig(this.contract, 'withdrawExcessRewards', [recipient]);
  }

  async getStartTimestamp(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.startTimestamp();
  }

  async getEndTimestamp(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.endTimestamp();
  }

  async getTotalStaked(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.totalStaked();
  }

  async getStakingToken(): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.stakingToken();
  }

  async getRewardPerSecond(index: number): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.rewardPerSecond(index);
  }

  async getRewardTokens(): Promise<string[]> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const count = await this.contract.getRewardTokensCount();
    const tokens = [];

    for (let i = 0; i < count; i++) {
      tokens.push(await this.contract.rewardsTokens(i));
    }

    return tokens;
  }

  async getStakeLimit(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.stakeLimit();
  }

  async getContractStakeLimit(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.contractStakeLimit();
  }

  async getAvailableBalance(index: number): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.getAvailableBalance(index);
  }

  async getExtensionDuration(): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.extensionDuration();
  }

  async getExtensionRewardPerSecond(index: number): Promise<BigNumber> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.extensionRewardPerSecond(index);
  }

  async isDone(): Promise<boolean> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const endTimestamp = await this.contract.endTimestamp();

    return Date.now() / 1000 > endTimestamp;
  }

  async transferOwnerShipToMultisig(): Promise<void> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const wallet = loadContract('wallet') as MultiSigWallet;

    if (wallet === undefined) {
      throw new Error(MULTISIG_DOES_NOT_EXIST);
    }

    const transferTx = await this.contract.transferOwnership(wallet.address);
    await transferTx.wait();
  }

  async acceptOwnerShip(): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await sendMultisig(this.contract, 'acceptOwnership', []);
  }

  async isMultisigOwner(): Promise<boolean> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    const wallet = loadContract('wallet') as MultiSigWallet;

    if (wallet === undefined) {
      throw new Error(MULTISIG_DOES_NOT_EXIST);
    }

    const owner = await this.contract.owner();
    return owner === wallet.address;
  }

  async hasStarted(): Promise<boolean> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return this.contract.hasStakingStarted();
  }

  async getName(): Promise<string> {
    if (this.contract === null) throw new Error('Trying to use unloaded contract');

    return await this.contract.name();
  }
}
