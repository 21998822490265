import { all, call, CallEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects';
import { providers, Signer } from 'ethers';

import {
  connectWeb3Provider,
  connectWeb3ProviderFail,
  connectWeb3ProviderSuccess,
  reconnectWeb3Provider,
  setWeb3Provider,
} from './actions';
import { connectWallet, setNetwork, setExplorer } from '../../auth/actions';
import { Network } from '@ethersproject/networks';
import { addSnackbar } from '../../snackbar/action';
import { useConnect } from 'wagmi';
import { getEthersWeb3Provider } from './selectors';
import { Web3ProviderState } from './slice';

export function* getNetwork(
  provider: WagmiConnectionResult['provider'],
): Generator<Promise<Network>, 'eth' | 'bsc', Network> {
  const chainId = (yield provider.getNetwork()).chainId;

  switch (chainId) {
    case 97:
    case 56:
      return 'bsc';
    case 1:
    case 5:
      return 'eth';
    default:
      return 'eth';
  }
}

export function* getExplorer(
  provider: WagmiConnectionResult['provider'],
): Generator<Promise<Network>, string, Network> {
  const chainId = (yield provider.getNetwork()).chainId;

  switch (chainId) {
    case 1:
      return 'https://etherscan.io/';
    case 5:
      return 'https://goerli.etherscan.io/';
    case 56:
      return 'https://bscscan.com/';
    case 137:
      return 'https://polygonscan.com/';
    case 80001:
      return 'https://mumbai.polygonscan.com/';
    case 43113:
      return 'https://cchain.explorer.avax-test.network/';
    case 43114:
      return 'https://cchain.explorer.avax.network/';
    case 1284:
      return 'https://moonscan.io/';
    default:
      return 'https://etherscan.io/';
  }
}

export function* reconnectUser({
  payload,
}: {
  payload: WagmiConnectionResult['provider'];
}): Generator<PutEffect, void, void> {
  yield put(connectWallet(payload));
}

export type WagmiConnectionResult = Awaited<ReturnType<ReturnType<typeof useConnect>['connectAsync']>>;

export function* connect({
  payload: provider,
}: {
  payload: WagmiConnectionResult['provider'];
}): Generator<
  CallEffect | PutEffect | SelectEffect,
  void,
  providers.ExternalProvider & [string, string] & string & Web3ProviderState & WagmiConnectionResult
> {
  try {
    const web3 = yield select(getEthersWeb3Provider);

    yield put(setWeb3Provider({ provider, signer: web3?.signer as Signer }));

    if (!!provider?.getNetwork) {
      const network = yield call(getNetwork, provider);
      const explorer = yield call(getExplorer, provider);
      yield put(setNetwork(network));
      yield put(setExplorer(explorer));
    }

    yield put(connectWeb3ProviderSuccess());
  } catch (e) {
    console.error(e);
    yield put(
      addSnackbar({
        type: 'error',
        title: 'Web3 connection failed',
        content: 'Unable to connect to web3 provider',
      }),
    );
    yield put(connectWeb3ProviderFail());
  }
}

export function* ethersWeb3SagaWatcher(): Generator {
  yield all([takeEvery(reconnectWeb3Provider, reconnectUser), takeEvery(connectWeb3Provider, connect)]);
}
