import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';

import { Button } from '../../components/Button';
import { TextField } from '../../components/TextField';

import { getEthersWeb3Provider } from '../../ducks/ethers/web3/selectors';
import { ethers, utils } from 'ethers';
import { getUserByAddress } from '../../services/user';
import { getAuthState } from '../../ducks/auth/selectors';
import { addSnackbar } from '../../ducks/snackbar/action';
import { getNetworkName } from '../../sdk/helpers/network';
import { getSignature } from '../../utils/getSignature';
import {
  emailRegex,
  usernameRegex,
  projectNameRegex,
  websiteRegex,
  symbolRegex,
  decimalsRegex,
} from '../../sdk/constants/regex';
import { getCoingeckoTokenInfo, getCoingeckoTokenInfoByTokenAddress } from '../../sdk/helpers/coingecko';
import { useAccount, useNetwork } from 'wagmi';
import { networkConfigurations } from '../../sdk/constants/networkConfig';
import { loadERC20 } from '../../sdk/helpers/loadERC20';
import { CoingeckoInputField } from '../../components/CoingeckoInputField';

class WalletError extends Error {
  response: { data: { error: string } } = { data: { error: '' } };

  constructor(error = 'Please connect a wallet before registering') {
    super(error);
    this.response.data.error = error;
  }
}

export const Onboarding: FC = () => {
  const { address: wallet, isConnecting } = useAccount();
  const { chain } = useNetwork();

  const dispatch = useDispatch();
  const authState = useSelector(getAuthState);

  const [state, setState] = useState('user');

  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);

  const [username, setUsername] = useState('');
  const [validUsername, setValidUsername] = useState(false);

  const [awaitingRegister, setAwaitingRegister] = useState(false);
  const [alreadyRegistered, setAlreadyRegistered] = useState(false);

  const [projectName, setProjectName] = useState('');
  const [validProjectName, setValidProjectName] = useState(false);

  const [projectWebsite, setProjectWebsite] = useState('');
  const [validProjectWebsite, setValidProjectWebsite] = useState(false);

  const [projectToken, setEthProjectToken] = useState('');
  const [projectTokenErrorMessage, setEthProjectTokenErrorMessage] = useState('');
  const [ethValidProjectToken, setEthValidProjectToken] = useState(false);

  const [projectTokenSymbol, setProjectTokenSymbol] = useState('');
  const [ethValidProjectTokenSymbol, setValidProjectTokenSymbol] = useState(false);

  const [projectCoingeckoId, setProjectCoingeckoId] = useState('');
  const [projectCoingeckoAddress, setProjectCoingeckoAddress] = useState('');
  const [projectCoingeckoUrl, setProjectCoingeckoUrl] = useState('');
  const [projectCoingeckoIcon, setProjectCoingeckoIcon] = useState('');
  const [projectCoingeckoNotFound, setProjectCoingeckoNotFound] = useState(false);
  const [validResultCoingeckoId, setValidResultCongeckoId] = useState(false);
  const [coinList, setCoinList] = useState<
    {
      id: string;
      symbol: string;
      name: string;
    }[]
  >([]);

  const [projectDecimals, setProjectDecimals] = useState('');
  const [validProjectDecimals, setValidProjectDecimals] = useState(false);

  const [awaitingProjectCreation, setAwaitingProjectCreation] = useState(false);
  const web3 = useSelector(getEthersWeb3Provider);

  const [loadedToken, setLoadedToken] = useState(false);

  const COINGECKO_COINS_URL = 'https://www.coingecko.com/en/coins';

  useEffect(() => {
    const fetchUser = async (): Promise<void> => {
      if (wallet) {
        const user = await getUserByAddress(wallet);
        if (user) {
          if (user.email) setEmail(user.email);
          setAlreadyRegistered(true);
          setValidEmail(true);
          setUsername(user.user);
          setValidUsername(true);
          if (user.projectId) {
            setState('finished');
          } else {
            setState('project');
          }
        }
      }
    };
    fetchUser();
  }, [isConnecting, wallet]);

  useEffect(() => {
    if (authState == 'unsupported_network') setState('unsupported_network');
  }, [authState, state]);

  useEffect(() => {
    const splittedResult = projectCoingeckoUrl.split('/');
    const tokenId = splittedResult[splittedResult.length - 1];
    const simpleCoingeckoRegex = 'coingecko.com/.{2,4}/coins';
    const results = coinList.filter((item: any) => item.id === tokenId);

    setValidResultCongeckoId(false);

    if (projectCoingeckoUrl === '') setValidResultCongeckoId(true);

    if (results.length > 0 && projectCoingeckoUrl.match(simpleCoingeckoRegex)) setValidResultCongeckoId(true);
  }, [projectCoingeckoUrl]);

  useEffect(() => {
    async function fetchCoinList(): Promise<void> {
      try {
        const response = await axios.get(`${process.env.REACT_APP_COINGECKO_API}/coins/list`);
        const { data } = response;

        setCoinList(data);
      } catch (error) {
        console.error(error);
      }
    }

    fetchCoinList();
  }, []);

  const register = (): void => {
    if (alreadyRegistered) {
      // already completed register step, jump to project step
      setState('project');
    } else if (email && username) {
      setAwaitingRegister(true);

      (async (): Promise<void> => {
        const { signature, timestamp } = await getSignature(web3, dispatch);

        await axios.post(process.env.REACT_APP_API + '/user', {
          email,
          username,
          wallet,
          signature: signature,
          timestamp,
          network: await getNetworkName(),
        });

        setState('project');
        setAwaitingRegister(false);
        setAlreadyRegistered(true);
      })().catch((e) => {
        dispatch(
          addSnackbar({
            type: 'error',
            title: 'Registration failed',
            content: e.response?.data ? e.response.data.error : 'There was an error during registration',
          }),
        );
        setAwaitingRegister(false);
      });
    }
  };

  useEffect(() => {
    if (projectToken && ethers.utils.isAddress(projectToken)) {
      getTokenInformation(projectToken).catch((e) => {
        setProjectCoingeckoId('');
        setProjectCoingeckoIcon('');
        setProjectTokenSymbol('');
        setProjectDecimals('');
        setLoadedToken(false);
        setEthValidProjectToken(false);
      });
    } else {
      setProjectCoingeckoId('');
      setProjectCoingeckoIcon('');
      setProjectTokenSymbol('');
      setProjectDecimals('');
      setLoadedToken(false);
      setEthValidProjectToken(false);
      setEthProjectTokenErrorMessage('Invalid token address');
    }
  }, [projectToken]);

  const getTokenInformation = async (tokenAddress: string): Promise<void> => {
    const tokenInfo = await getCoingeckoTokenInfoByTokenAddress(
      networkConfigurations[chain?.id as number].COINGECKO_CHAIN_ID,
      tokenAddress,
    );

    if (tokenInfo?.error === 'coin not found') {
      // fallback to back method
      const token = loadERC20(tokenAddress);
      setProjectTokenSymbol(await token.getSymbol());
      setProjectDecimals((await token.getDecimals()).toString());
      setValidProjectTokenSymbol(true);
      setValidProjectDecimals(true);
      setLoadedToken(true);
      setEthValidProjectToken(true);
      setProjectCoingeckoNotFound(true);
      return;
    }

    setProjectCoingeckoId(tokenInfo.id);
    setProjectCoingeckoIcon(tokenInfo?.image?.thumb);
    setProjectCoingeckoUrl(`${COINGECKO_COINS_URL}/${tokenInfo.id}`);
    setProjectTokenSymbol(tokenInfo.symbol.toUpperCase());
    setProjectDecimals(tokenInfo.detail_platforms[tokenInfo.asset_platform_id].decimal_place.toString());
    setValidProjectTokenSymbol(true);
    setValidProjectDecimals(true);
    setLoadedToken(true);
    setEthValidProjectToken(true);
  };

  const createProject = (): void => {
    if (projectName && projectWebsite && projectToken && projectTokenSymbol) {
      setAwaitingProjectCreation(true);

      const splitArr = projectCoingeckoUrl.split('/');
      const coinGeckoID = splitArr[splitArr.length - 1];

      (async (): Promise<void> => {
        const { signature, timestamp } = await getSignature(web3, dispatch);

        try {
          const data: { [key: string]: any } = {
            name: projectName,
            website: projectWebsite,
            tokenAddress: utils.getAddress(projectToken).toString(),
            tokenSymbol: projectTokenSymbol,
            coinGeckoID: coinGeckoID,
            wallet,
            signature: signature,
            timestamp,
            decimals: parseInt(projectDecimals, 10),
            network: await getNetworkName(),
          };

          await axios.post(process.env.REACT_APP_API + '/project', data);

          setAwaitingProjectCreation(false);
          setState('finished');
          window.setTimeout(() => {
            document.location.reload();
          }, 3000);
        } catch (e: any) {
          console.error(e);
          setAwaitingProjectCreation(false);
          dispatch(
            addSnackbar({
              type: 'error',
              title: 'Registration failed',
              content: e.response?.data ? e.response.data.error : 'There was an error during registration',
            }),
          );
        }
      })();
    }
  };

  useEffect(() => {
    const loadProjectCoingeckoIcon = async (): Promise<void> => {
      if (projectCoingeckoUrl) {
        const coingeckoId = projectCoingeckoUrl.replace(`${COINGECKO_COINS_URL}/`, '');
        const tokenInfo = await getCoingeckoTokenInfo(coingeckoId);
        if (tokenInfo?.image?.thumb) {
          setProjectCoingeckoIcon(tokenInfo.image.thumb);
        }
        return;
      }
      setProjectCoingeckoIcon('');
    };

    loadProjectCoingeckoIcon();
  }, [projectCoingeckoUrl]);

  return (
    <div className="onboarding">
      <img src="/images/onboarding-graphic.svg" alt="Onboarding graphic" className="onboarding-graphic" />

      {state === 'user' && (
        <div className="onboarding-form">
          <div className="onboarding-header">
            Register
            <div className="spacer"></div>
            <div className="steps">
              Steps:
              <div className="step-big"></div>
              <div className="step-small"></div>
              <div className="step-small"></div>
            </div>
          </div>

          <div className="onboarding-info">
            You are about to create a new project, but first we need some basic information
          </div>

          <div className="onboarding-panel">
            <TextField
              readOnly={alreadyRegistered}
              value={email}
              label="Email"
              type="text"
              icon="email"
              onChange={(value): void => {
                setEmail(value);
              }}
              onValidate={(value): string => (emailRegex.test(value) ? '' : 'Invalid email address')}
              onValidationChange={setValidEmail}
            />

            <TextField
              readOnly={alreadyRegistered}
              value={username}
              label="Username"
              type="text"
              icon="admins"
              onChange={(value): void => {
                setUsername(value);
              }}
              onValidate={(value): string =>
                usernameRegex.test(value) ? '' : 'Invalid username (min 3, max 32 characters'
              }
              onValidationChange={setValidUsername}
            />
          </div>

          <div className="spacer"></div>

          <div className="onboarding-buttons">
            <Button
              color="primary"
              size="large"
              onClick={register}
              label={awaitingRegister ? 'Registering...' : 'Next'}
              icon="arrow_long_right"
              iconposition="right"
              disabled={!validUsername || !validEmail}
            />
          </div>
        </div>
      )}

      {state === 'project' && (
        <div className="onboarding-form">
          <div className="onboarding-header">
            Project info
            <div className="spacer"></div>
            <div className="steps">
              Steps:
              <div className="step-small"></div>
              <div className="step-big"></div>
              <div className="step-small"></div>
            </div>
          </div>

          <div className="onboarding-info">Please give us some information about your project</div>

          <div className="onboarding-panel">
            <TextField
              value={projectName}
              label="Project name"
              type="text"
              icon="project"
              onChange={(value): void => setProjectName(value)}
              onValidate={(value): string =>
                projectNameRegex.test(value) ? '' : 'Invalid project name (min 3, max 64 characters)'
              }
              onValidationChange={setValidProjectName}
            />

            <TextField
              value={projectWebsite}
              label="Project website"
              type="text"
              icon="website"
              onChange={(value): void => setProjectWebsite(value)}
              onValidate={(value): string => (websiteRegex.test(value) ? '' : 'Invalid website')}
              onValidationChange={setValidProjectWebsite}
            />

            <TextField
              value={projectToken}
              label="ERC20 Token address"
              type="text"
              icon="token"
              onChange={(v): void => {
                const value = ethers.utils.isAddress(v) ? v : '';
                setEthProjectToken(value);
              }}
            />
            <span className="text-field-validation textField header-small custom-error-message">
              {ethValidProjectToken ? null : projectTokenErrorMessage}
            </span>

            <TextField
              value={projectTokenSymbol}
              label="ERC20 Token symbol"
              type="text"
              icon="symbol"
              onChange={(value): void => setProjectTokenSymbol(value)}
              onValidate={(value): string => (symbolRegex.test(value) ? '' : 'Invalid symbol')}
              onValidationChange={setValidProjectTokenSymbol}
              disabled={loadedToken}
            />

            {!projectCoingeckoNotFound ? (
              <TextField
                value={projectCoingeckoId}
                label="Coingecko ID"
                type="text"
                icon="assets"
                iconUrl={projectCoingeckoIcon}
                disabled={loadedToken}
              />
            ) : (
              <CoingeckoInputField
                id="lpTokens"
                name="rewardToken"
                address={projectCoingeckoAddress}
                tokenSymbol={projectTokenSymbol}
                tokenIcon={projectCoingeckoIcon}
                onChange={async (coinGeckoID: string): Promise<void> => {
                  let tokenInfo;
                  for (const token of coinList) {
                    if (token.id === coinGeckoID) {
                      tokenInfo = token;
                      break;
                    }
                  }
                  if (tokenInfo) {
                    setProjectTokenSymbol(tokenInfo?.symbol ? tokenInfo.symbol.toUpperCase() : '');
                    setProjectCoingeckoUrl(`${COINGECKO_COINS_URL}/${coinGeckoID}`);
                    return;
                  }
                  setProjectCoingeckoAddress('');
                  setProjectTokenSymbol('');
                  setProjectCoingeckoUrl('');
                }}
                autocomplete={coinList}
                title={'Coingecko ID'}
                label={coinList ? 'Enter project name / token ticker' : 'Coingecko URL'}
                required={true}
                icon="discord_icon_input"
                type="text"
                fileInputName="basic_setup_token_image"
                onChangeFile={(): void => {
                  return;
                }}
                fileValue={''}
                hideAddIconBtn
              />
            )}

            <TextField
              value={projectDecimals}
              label="Decimals"
              type="text"
              icon="website"
              onChange={(value): void => setProjectDecimals(value)}
              onValidate={(value): string => (decimalsRegex.test(value) ? '' : 'Invalid decimals')}
              onValidationChange={setValidProjectDecimals}
              disabled={loadedToken}
            />
          </div>

          <div className="spacer"></div>

          <div className="onboarding-buttons">
            <Button
              color="tertiary"
              size="large"
              onClick={(): void => {
                setState('user');
              }}
              label="Go back"
              icon="arrow_long_left"
              iconposition="left"
            />

            <Button
              color="primary"
              size="large"
              onClick={createProject}
              label={awaitingProjectCreation ? 'Processing...' : 'Complete'}
              icon="arrow_long_right"
              iconposition="right"
              disabled={
                !validProjectName ||
                !validProjectWebsite ||
                !ethValidProjectToken ||
                !ethValidProjectTokenSymbol ||
                !validResultCoingeckoId ||
                !validProjectDecimals
              }
            />
          </div>
        </div>
      )}

      {state === 'finished' && (
        <div className="onboarding-form">
          <div className="onboarding-header">
            Finished
            <div className="spacer"></div>
            <div className="steps">
              Steps:
              <div className="step-small"></div>
              <div className="step-small"></div>
              <div className="step-big"></div>
            </div>
          </div>

          <div className="onboarding-info">
            Your account has been created successfully. You will be redirected in 3 seconds.
          </div>
        </div>
      )}

      {state === 'unsupported_network' && (
        <div className="onboarding-form">
          <div className="onboarding-header">
            Unsupported Network
            <div className="spacer"></div>
          </div>

          <div className="onboarding-info">Please choose a different network.</div>
        </div>
      )}
    </div>
  );
};
