import { BigNumber } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-light.svg';
import { Link } from 'react-router-dom';
import { getProjectConfigAll } from '../../ducks/project/selectors';
import { addSnackbar } from '../../ducks/snackbar/action';
import { addDecimals } from '../../sdk/helpers/addDecimals';
import { getPaymentCredits } from '../../sdk/helpers/getPaymentCredits';
import { isWhitelisted, paymentUseCreditExtension } from '../../sdk/payment';
import { checkRewardBalanceV1, checkRewardBalanceV2 } from '../../sdk/helpers/checkRewardBalance';
import {
  Duration,
  createDuration,
  DurationUnits,
  getFutureBlockNumber,
  durationToUnit,
  durationToSeconds,
  durationToDays,
} from '../../sdk/helpers/duration';
import { TokenInformation, getTokenInformation } from '../../sdk/helpers/getTokenInformation';
import { getNetworkName } from '../../sdk/helpers/network';
import { LiquidityMiningCampaignFactory } from '../../sdk/staking-v1/LiquidityMiningCampaignFactory';
import { LiquidityMiningCampaign } from '../../sdk/staking-v2/LiquidityMiningCampaign';
import { RewardsPoolBase } from '../../sdk/staking-v2/RewardsPoolBase';
import { dateToString, timeToString } from '../../utils/formatDate';
import { formatTokens } from '../../utils/formatNumber';
import { handleMetamaskError } from '../../utils/handleError';
import { Button } from '../Button';
import { PoolVersions } from '../../utils/helpers';
import { DurationInput } from '../DurationInput';
import { Pool } from '../LiquidityMiningPool';
import { Modal } from '../Modal';
import { WeiAmount, WeiInput } from '../WeiInput';
import axios from 'axios';
import { getSignature } from '../../utils/getSignature';
import { getEthersWeb3Provider } from '../../ducks/ethers/web3/selectors';
import { ClipboardTooltip } from '../ClipboardTooltip';
import { useAccount, useNetwork, useProvider } from 'wagmi';
import { formatDuration } from '../../utils/formatDuration';

export const PoolExtend: React.FC<{
  pool: Pool;
  onClose: () => void;
}> = ({ pool, onClose }) => {
  const { address: wallet } = useAccount();
  const { chain } = useNetwork();
  const provider = useProvider();

  const dispatch = useDispatch();
  const config = useSelector(getProjectConfigAll);
  const web3 = useSelector(getEthersWeb3Provider);
  const [network, setNetwork] = useState('');

  const [extendDuration, setExtendDuration] = useState<Duration>(createDuration(0, DurationUnits.days));
  const [extendRewards, setExtendRewards] = useState<
    { readOnly: boolean; info: TokenInformation; reward: WeiAmount }[]
  >([]);
  const [extendingPool, setExtendingPool] = useState(false);
  const [extendBalanceNeeded, setExtendFactoryBalanceNeeded] = useState('');
  const [newExtenedDate, setNewExtenedDate] = useState(pool.end);

  const [whitelisted, setWhitelisted] = useState<boolean>(false);
  const [hasCredits, setHasCredits] = useState<number>(0);
  const [multisigAddress, setMultisigAddress] = useState<any>('');

  useEffect(() => {
    getNetworkName().then((network) => {
      setNetwork(network);
      const multisigAddress =
        config.multisig[network as 'eth' | 'bsc' | 'polygon' | 'avalanche' | 'ewc' | 'moonbeam' | 'songbird'];
      if (multisigAddress) {
        setMultisigAddress(multisigAddress);
        isWhitelisted(multisigAddress).then((whitelistResult) => setWhitelisted(whitelistResult));
      }
    });
  }, []);

  useEffect(() => {
    const newDate = new Date(pool.end);
    newDate.setSeconds(newDate.getSeconds() + durationToSeconds(extendDuration));
    setNewExtenedDate(newDate);
  }, [extendDuration]);

  const checkBalanceV1 = async (): Promise<void> => {
    if (!config || !config.config || !config.config.factory || !config.config.factory[network]) {
      return;
    }

    checkRewardBalanceV1(
      config.config.factory[network],
      extendRewards.map((reward, rewardIndex) => {
        const campaignReward = pool.campaignRewards[rewardIndex];
        if (!campaignReward || campaignReward.address !== reward.info.address)
          throw new Error('Campaign reward not found');

        let rewardNeeded = BigNumber.from(addDecimals(reward.reward.amount || '0', reward.reward.decimals)).sub(
          campaignReward.remainingRewardAmount,
        );
        if (rewardNeeded.lt(0)) rewardNeeded = BigNumber.from(0);

        return {
          ...reward,
          reward: {
            decimals: reward.reward.decimals,
            amount: rewardNeeded.div(BigNumber.from(10).pow(reward.reward.decimals)),
          },
        };
      }),
    ).then((needed) => {
      setExtendFactoryBalanceNeeded(needed);
    });
  };

  const checkBalanceV2 = async (): Promise<void> => {
    const rewardsPoolV2 = new RewardsPoolBase();
    await rewardsPoolV2.load(pool.address);

    checkRewardBalanceV2(rewardsPoolV2, extendRewards).then((needed) => {
      setExtendFactoryBalanceNeeded(needed);
    });
  };

  const checkBalance = (): void => {
    if (pool.version === PoolVersions.v1) checkBalanceV1();
    if (pool.version === PoolVersions.v2 || pool.version === PoolVersions.v3) checkBalanceV2();
  };

  // check payment credit
  useEffect(() => {
    if (!whitelisted) {
      const intervalId = setInterval(() => {
        (async (): Promise<void> => {
          if (multisigAddress) {
            getPaymentCredits(multisigAddress, 0).then((res) => {
              setHasCredits(Number(res));
            });
          }
          clearInterval(intervalId);
        })();
      }, 1000);

      return (): void => {
        clearInterval(intervalId);
        setHasCredits(0);
      };
    }
  }, [extendDuration, pool]);

  useEffect(() => {
    checkBalance();

    const intervalId = setInterval(checkBalance, 5000);
    return (): void => {
      clearInterval(intervalId);
    };
  }, [extendRewards]);

  useEffect(() => {
    if (pool) {
      Promise.all(
        pool.campaignRewards.map((reward) =>
          getTokenInformation({ chainId: chain?.id as number, tokenAddress: reward.address, provider }),
        ),
      ).then((infos) => {
        if (infos.length !== pool.campaignRewards.length) throw new Error('Error while getting reward information');

        setExtendRewards(
          infos.map((info) => ({
            readOnly: true,
            info: info,
            reward: {
              amount: BigNumber.from(0),
              decimals: info.decimals || 18,
            },
          })),
        );
      });
    }
  }, [pool]);

  const extendRewardPoolV1 = async (): Promise<void> => {
    if (!config.config || !config.config.factory || !config.config.factory[network]) {
      dispatch(
        addSnackbar({ type: 'error', title: 'No factory', content: 'Please create a factory before proceeding.' }),
      );
      return;
    }

    const factory = new LiquidityMiningCampaignFactory();
    await factory.load(config.config.factory[network]);

    await factory.extendRewardPool(
      extendDuration,
      extendRewards.map((reward) => addDecimals(reward.reward.amount, reward.reward.decimals)),
      pool.address,
    );
  };

  const extendRewardPoolV2 = async (): Promise<void> => {
    const lmPool = new LiquidityMiningCampaign();
    await lmPool.load(pool.address);

    await lmPool.extend(
      extendDuration,
      extendRewards.map((reward) => addDecimals(reward.reward.amount, reward.reward.decimals)),
    );

    const { signature, timestamp } = await getSignature(web3, dispatch);

    await axios.post(process.env.REACT_APP_API + '/project/add-duration', {
      wallet,
      signature: signature,
      campaignAddress: pool.address,
      timestamp,
      period: `${extendDuration.value}-${extendDuration.unit}`,
    });
  };

  const extendRewardPoolV3 = async (): Promise<void> => {
    await paymentUseCreditExtension(
      extendDuration,
      extendRewards.map((reward) => addDecimals(reward.reward.amount, reward.reward.decimals)),
      pool.address,
    );

    const { signature, timestamp } = await getSignature(web3, dispatch);

    await axios.post(process.env.REACT_APP_API + '/project/add-duration', {
      wallet,
      signature: signature,
      campaignAddress: pool.address,
      timestamp,
      period: `${extendDuration.value}-${extendDuration.unit}`,
    });
  };

  const extend = async (): Promise<void> => {
    setExtendingPool(true);

    try {
      if (pool.version === PoolVersions.v1) await extendRewardPoolV1();
      if (pool.version === PoolVersions.v2) await extendRewardPoolV2();
      if (pool.version === PoolVersions.v3) await extendRewardPoolV3();
    } catch (e: any) {
      handleMetamaskError(e, dispatch, 'Extend campaign');
      setExtendingPool(false);
      return;
    }

    dispatch(
      addSnackbar({
        type: 'info',
        title: 'Extension success',
        content: 'The extension is now awaiting multisig confirmation',
      }),
    );

    setExtendRewards(
      extendRewards.map((r, i) => {
        r.reward = {
          amount: BigNumber.from(0),
          decimals: r.reward.decimals,
        };
        return r;
      }),
    );

    setExtendingPool(false);
    onClose();
  };

  const validateValues = (): string | JSX.Element => {
    try {
      if (
        pool.version === PoolVersions.v1 &&
        durationToUnit(extendDuration, DurationUnits.seconds) <= (pool.end.getTime() - Date.now()) / 1000
      ) {
        return 'Duration needs to be greater than remaining duration';
      }

      if (
        (pool.version === PoolVersions.v2 || pool.version === PoolVersions.v3) &&
        durationToUnit(extendDuration, DurationUnits.seconds) <= 0
      ) {
        return 'Duration needs to be greater than 0';
      }

      if (
        (pool.version === PoolVersions.v2 || pool.version === PoolVersions.v3) &&
        durationToUnit(extendDuration, DurationUnits.seconds) > (pool.end.getTime() - pool.start.getTime()) / 1000
      ) {
        return 'Duration needs to be equal or lower than current duration of the campaign';
      }

      if (pool.version === PoolVersions.v3 && hasCredits <= 0 && !whitelisted)
        return (
          <div>
            You need an extension credit to extend the campaign. Go to the <Link to="/credits">credits</Link> page to
            buy a credit.
          </div>
        );

      if (!extendRewards.every((reward) => BigNumber.from(reward.reward.amount).gt(0))) {
        return 'Reward needs to be greater than 0';
      }

      return '';
    } catch (e) {
      console.error(e);
      return 'Error while validation values';
    }
  };

  return (
    <Modal title="Extend campaign" onClose={onClose}>
      {pool.version === PoolVersions.v1 && (
        <div className="modal-text">
          Extend your campaign by setting new values for ‘Duration’ and ‘Rewards’ that are higher than the current
          values. Note that your new input will overwrite the old values and not add to them, so only higher values will
          be accepted.
        </div>
      )}

      {(pool.version === PoolVersions.v2 || pool.version === PoolVersions.v3) && (
        <div className="modal-text">
          Extend your campaign by setting the ‘Duration’ and ‘Rewards’ for the next period. The new period will set
          right after the end of the current period. The duration of the extended campaign can not be longer than the
          length of the previous campaign. ( Previous campaign duration:{' '}
          {formatDuration(createDuration(pool.end.getTime() - pool.start.getTime(), DurationUnits.milliseconds))})
        </div>
      )}

      <div className="modal-form" style={{ height: 360 + 'px' }}>
        <div className="modal-form-header">Set duration</div>

        <div className="flex modal-form-duration">
          <DurationInput
            value={extendDuration}
            onChange={(value: Duration): void => {
              setExtendDuration(value);
            }}
          />
        </div>

        {extendDuration.value > 0 && (
          <div className="pool-extend-extra-information">
            New expiry date will be:{' '}
            <strong>{`${dateToString(newExtenedDate)} ${timeToString(newExtenedDate)}`}</strong>
          </div>
        )}

        <div className="modal-form-header">Set reward</div>
        {extendRewards.map((reward, rewardIndex) => {
          return (
            <div className="pool-form-reward" key={rewardIndex}>
              <WeiInput
                label={reward.info.symbol + ' reward'}
                value={reward.reward}
                onChange={(value): void => {
                  setExtendRewards(
                    extendRewards.map((r, i) => {
                      if (i === rewardIndex) r.reward = value;
                      return r;
                    }),
                  );
                }}
                onValidate={(value: WeiAmount): string => {
                  const campaignReward = pool.campaignRewards[rewardIndex];
                  if (!campaignReward || campaignReward.address !== reward.info.address)
                    return 'Campaign reward not found';

                  const newRewards = BigNumber.from(addDecimals(value.amount || '0', value.decimals));
                  if (newRewards.lt(campaignReward.remainingRewardAmount)) {
                    return (
                      'Reward amount must be greater or equal to the remaining reward amount (' +
                      formatTokens(campaignReward.remainingRewardAmount, 18) +
                      ')'
                    );
                  }

                  return '';
                }}
              />
            </div>
          );
        })}

        {!!validateValues() && <div className="pool-extend-error">{validateValues()}</div>}

        {!validateValues() && extendBalanceNeeded.length > 0 && (
          <div className="pool-extend-info">
            There are not enough rewards in the contract, you need to send {extendBalanceNeeded}
            &nbsp;to {pool.version === PoolVersions.v1 ? config.config.factory?.[network] : pool.address}{' '}
            <ClipboardTooltip
              content={(pool.version === PoolVersions.v1 ? config.config.factory?.[network] : pool.address) as string}
            >
              <CopyIcon className="copy-icon" />
            </ClipboardTooltip>
          </div>
        )}
      </div>

      <div className="modal-buttons">
        <Button
          color="primary"
          size="medium"
          onClick={extend}
          label="Confirm"
          iconposition="left"
          icon={extendingPool ? 'reload' : 'check'}
          disabled={
            extendingPool || !!validateValues() || extendBalanceNeeded.length > 0 || !(extendDuration.value > 0)
          }
          spin={extendingPool}
        />
      </div>
    </Modal>
  );
};
