import { avalanche, avalancheFuji, bsc, goerli, mainnet, polygon, polygonMumbai } from '@wagmi/chains';
import { Chain, configureChains, createClient } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { publicProvider } from 'wagmi/providers/public';

const networks: { [chainId: number]: Chain } = {
  1: {
    ...mainnet,
    rpcUrls: {
      default: String(process.env.REACT_APP_ETH_RPC),
    },
  },
  5: {
    ...goerli,
    rpcUrls: {
      default: String(process.env.REACT_APP_GOERLI_RPC),
    },
  },
  19: {
    id: 19,
    name: 'Songbird',
    network: 'sgb',
    nativeCurrency: {
      decimals: 18,
      name: 'SGB',
      symbol: 'SGB',
    },
    rpcUrls: {
      default: String(process.env.REACT_APP_SONGBIRD_RPC),
      public: 'https://songbird-api.flare.network/ext/C/rpc',
    },
    blockExplorers: {
      etherscan: { name: 'Songbird Explorer', url: 'https://songbird-explorer.flare.network/' },
      default: { name: 'Songbird Explorer', url: 'https://songbird-explorer.flare.network/' },
    },
  },
  56: {
    ...bsc,
    rpcUrls: { default: String(process.env.REACT_APP_BSC_RPC) },
  },
  137: {
    ...polygon,
    rpcUrls: {
      default: String(process.env.REACT_APP_POL_RPC),
      public: 'https://polygon-rpc.com',
      maticVigil: 'https://rpc-mainnet.maticvigil.com',
      blastApi: 'https://polygon-mainnet.public.blastapi.io',
      pokt: 'https://poly-rpc.gateway.pokt.network',
      publicBor: 'https://polygon-bor.publicnode.com',
    },
  },
  43_114: {
    ...avalanche,
    rpcUrls: {
      default: String(process.env.REACT_APP_AVA_RPC),
    },
  },
  246: {
    id: 246,
    name: 'Energy Web Chain',
    network: 'ewc',
    nativeCurrency: {
      decimals: 18,
      name: 'EWT',
      symbol: 'EWT',
    },
    rpcUrls: { default: String(process.env.REACT_APP_EWC_RPC), public: 'https://rpc.energyweb.org' },
    blockExplorers: {
      etherscan: { name: 'EnergyWeb Explorer', url: 'https://explorer.energyweb.org' },
      default: { name: 'EnergyWeb Explorer', url: 'https://explorer.energyweb.org' },
    },
  },
  1284: {
    id: 1_284,
    name: 'Moonbeam',
    network: 'moonbeam',
    nativeCurrency: {
      decimals: 18,
      name: 'DEV',
      symbol: 'DEV',
    },
    rpcUrls: {
      default: String(process.env.REACT_APP_MOONBEAM_RPC),
      public: 'https://rpc.api.moonbeam.network',
    },
    blockExplorers: {
      etherscan: { name: 'Moonscan', url: 'https://moonscan.io' },
      default: { name: 'Moonscan', url: 'https://moonscan.io' },
    },
  },
};

const POLLING_INTERVAL = 12_000;

const { chains, provider } = configureChains(Object.values(networks), [publicProvider()], {
  pollingInterval: POLLING_INTERVAL,
});

export const metamaskConnector = new MetaMaskConnector({ chains });

export const walletConnectConnector = new WalletConnectConnector({
  chains,
  options: {
    qrcode: true,
  },
});

export const wagmiClient = createClient({
  autoConnect: true,
  connectors: [metamaskConnector, walletConnectConnector],
  provider,
});
