import { Icons } from '../assets/icons';
import { DropdownOption } from '../components/Dropdown';
import { TokenInformation } from '../sdk/helpers/getTokenInformation';
import { WeiAmount } from '../components/WeiInput';
import { Duration } from '../sdk/helpers/duration';
import { RewardsPoolBase } from '../sdk/staking-v2/RewardsPoolBase';

export const explorerChainId = {
  1: 'etherscan.io',
  5: 'goerli.etherscan.io',
  19: 'songbird-explorer.flare.network',
  56: 'bscscan.com',
  97: 'testnet.bscscan.com',
  137: 'polygonscan.com',
  246: 'explorer.energyweb.org',
  1284: 'moonscan.io',
  43113: 'cchain.explorer.avax-test.network',
  43114: 'cchain.explorer.avax.network',
  80001: 'mumbai.polygonscan.com',
};

type ExplorerChainId = keyof typeof explorerChainId;

export const getExplorerAddressByChainId = (chainId: string | undefined): string =>
  `https://${explorerChainId[(chainId as unknown) as ExplorerChainId] || 'etherscan.io'}/address/`;

export function getNetworkAndProtocol(id: string): [string, string] {
  switch (id) {
    case '97':
    case '56':
      return ['bsc', 'pancakeswap'];
    case '137':
      return ['polygon', 'quickswap'];
    case '43114':
      return ['avalanche', 'pangolin'];
    case '43113':
      return ['avalanche', 'pangolin'];
    case '1284':
      return ['moonbeam', 'solarflare'];
    case '246':
      return ['ewc', 'alliancedex'];
    case '19':
      return ['songbird', 'pangolin'];
    default:
      return ['eth', 'uniswap'];
  }
}

export function getProtocolOptions(network: string): DropdownOption[] {
  if (network === 'bsc') {
    return [
      {
        value: 'pancakeswap',
        icon: Icons['pancakeswap'],
        name: 'Pancakeswap',
      },
    ];
  }

  if (network === 'avalanche') {
    return [
      {
        value: 'pangolin',
        icon: Icons['pangolin'],
        name: 'Pangolin',
      },
      {
        value: 'traderjoe',
        icon: Icons['traderjoe'],
        name: 'Trader joe',
      },
    ];
  }

  if (network === 'polygon') {
    return [
      {
        value: 'quickswap',
        icon: Icons['quickswap'],
        name: 'Quickswap',
      },
    ];
  }

  if (network === 'moonbeam') {
    return [
      {
        value: 'solarflare',
        icon: Icons['solarflare'],
        name: 'Solarflare',
      },
    ];
  }

  if (network === 'songbird') {
    return [
      {
        value: 'pangolin',
        icon: Icons['pangolin'],
        name: 'Pangolin',
      },
    ];
  }

  if (network === 'ewc') {
    return [
      {
        value: 'alliancedex',
        icon: Icons['allianceblock'],
        name: 'AllianceBlock DEX',
      },
    ];
  }

  return [
    {
      value: 'uniswap',
      icon: Icons['uniswap'],
      name: 'Uniswap',
    },
    {
      value: 'balancer',
      icon: Icons['balancer'],
      name: 'Balancer',
    },
  ];
}

export function getVersionDropdownOptions(): DropdownOption[] {
  return [
    {
      value: PoolVersions.v1,
      icon: Icons.pool,
      name: 'version 1.0',
    },
    {
      value: PoolVersions.v2,
      icon: Icons.pool,
      name: 'version 2.0',
    },
    {
      value: PoolVersions.v3,
      icon: Icons.pool,
      name: 'version 3.0',
    },
  ].filter((option) => (process.env.REACT_APP_ALLOWED_VERSIONS || '1.0').split(',').includes(option.value));
}

export const minimumPoolScheduleTime = new Date().toISOString().slice(0, -1).slice(0, 16);

export const PoolVersions: {
  [key: string]: PoolVersion;
} = {
  v1: '1.0',
  v2: '2.0',
  v3: '3.0',
};

export type PoolVersion = '1.0' | '2.0' | '3.0';

export interface CreatePoolState {
  step: number;
  version: PoolVersion;
  name: string;
  campaignMessage: string;
  protocol: string;
  stakingAddress: string;
  userStakingLimit: WeiAmount;
  stakingLimit: WeiAmount;
  rewards: { readOnly: boolean; info: TokenInformation; reward: WeiAmount }[];
  throttleRoundDuration: Duration;
  throttleRoundCap: WeiAmount;
  pool: RewardsPoolBase;
}

export interface CreatePoolProps {
  title: string;
  subTitle: string;
  tokenTitle: string;
  tokenLabel: string;
  showProtocol: boolean;
  showTier?: boolean;
  showThrottleRound: boolean;
  onCreatePool: (
    protocol: string,
    lpAddress: string,
    rewards: { readOnly: boolean; info: TokenInformation; reward: WeiAmount }[],
    userStakingLimit: WeiAmount,
    stakingLimit: WeiAmount,
    throttleRoundDuration: Duration,
    throttleRoundCap: WeiAmount,
    tokenInformation: TokenInformation[],
    name: string,
    campaignMessage: string,
  ) => Promise<{ pool: RewardsPoolBase; info: any }>;
  rewardsLimit: number;
  singleSidedToken: boolean;
  type: number;
  state?: CreatePoolState;
}

export interface Reward {
  readOnly: boolean;
  info: TokenInformation;
  reward: WeiAmount;
}
