import { utils } from 'ethers';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNetwork } from 'wagmi';
import { Icons } from '../../assets/icons';
import { getProjectConfig } from '../../ducks/project/selectors';
import { addSnackbar } from '../../ducks/snackbar/action';
import { getNetworkName } from '../../sdk/helpers/network';
import { NonCompoundingRewardsPoolFactory } from '../../sdk/staking-v1/NonCompoundingRewardsPool';
import { NonCompoundingRewardsPool } from '../../sdk/staking-v2/NonCompoundingRewardsPool';
import { StakingInterface } from '../../types/dto/Project.dto';
import { Button } from '../Button';
import { PoolVersions, getExplorerAddressByChainId } from '../../utils/helpers';
import { Modal } from '../Modal';
import { Pool } from '../StakingPool';
import { TextField } from '../TextField';

export const PoolWhitelist: React.FC<{ pool: Pool; onClose: (wasWhitelisted: boolean) => void }> = ({
  pool,
  onClose,
}) => {
  const { chain } = useNetwork();

  const dispatch = useDispatch();
  const config = useSelector(getProjectConfig);
  const [network, setNetwork] = useState('');
  const [chainId, setChainId] = useState<string>('');

  const [whitelistAddresses, setWhitelistAddresses] = useState<string[]>(['']);
  const [whitelisting, setWhitelisting] = useState<boolean>(false);
  const [whitelistedAddresses, setWhitelistedAddresses] = useState<string[]>([]);
  const [loadingWhitelisting, setLoadingWhitelisting] = useState<boolean>(false);

  useEffect(() => {
    getNetworkName().then((network) => {
      setNetwork(network);
    });
  }, []);

  useEffect(() => {
    if (chain?.id) {
      setChainId(String(chain.id));
    }
    return (): void => {
      setChainId('');
    };
  }, [chain?.id]);

  useEffect(() => {
    const loadWhitelistedCampaigns = async (): Promise<void> => {
      setLoadingWhitelisting(true);
      const currentNetwork = await getNetworkName();
      const campaigns =
        config.campaignsStaking
          ?.filter((item: StakingInterface) => item.network === currentNetwork)
          .map((item: StakingInterface) => item.campaignAddress) || [];

      const whitelistedCampaigns = [];

      const stakingPool = new NonCompoundingRewardsPool();
      await stakingPool.load(pool.address);
      for (const campaign of campaigns) {
        const isWhitelisted = await stakingPool.receiversWhitelist(campaign);
        if (isWhitelisted) {
          whitelistedCampaigns.push(campaign);
        }
      }

      setWhitelistedAddresses(whitelistedCampaigns);
      setLoadingWhitelisting(false);
    };

    loadWhitelistedCampaigns();

    return (): void => {
      setLoadingWhitelisting(false);
    };
  }, [whitelisting]);

  const addToWhitelistV1 = async (): Promise<void> => {
    if (!config.stakingFactory || !config.stakingFactory[network]) {
      dispatch(
        addSnackbar({ type: 'error', title: 'No factory', content: 'Please create a factory before proceeding.' }),
      );
      return;
    }

    if (!pool) {
      dispatch(
        addSnackbar({
          type: 'error',
          title: 'No pool loaded',
          content: 'Please create a pool before whitelisting.',
        }),
      );
      return;
    }

    setWhitelisting(true);

    try {
      const factory = new NonCompoundingRewardsPoolFactory();
      await factory.load(config.stakingFactory[network]);

      await factory.enableReceivers(pool.address, whitelistAddresses);

      setWhitelisting(false);
      onClose(true);
    } catch (e) {
      console.error(e);
      dispatch(
        addSnackbar({
          type: 'error',
          title: 'Unexpected error',
          content: 'There was an error while whitelisting the pool, please contact support',
        }),
      );
      setWhitelisting(false);
      return;
    }
  };

  const addToWhitelistV2 = async (): Promise<void> => {
    if (!pool) {
      dispatch(
        addSnackbar({
          type: 'error',
          title: 'No pool loaded',
          content: 'Please create a pool before whitelisting.',
        }),
      );
      return;
    }

    setWhitelisting(true);

    try {
      const stakingPool = new NonCompoundingRewardsPool();
      await stakingPool.load(pool.address);

      for (const whitelistAddress of whitelistAddresses) {
        await stakingPool.setReceiverWhitelisted(whitelistAddress, true);
      }

      setWhitelisting(false);
      onClose(true);
    } catch (e) {
      console.error(e);
      dispatch(
        addSnackbar({
          type: 'error',
          title: 'Unexpected error',
          content: 'There was an error while whitelisting the pool, please contact support',
        }),
      );
      setWhitelisting(false);
      return;
    }
  };

  const campaignExists = (): boolean => {
    const campaignExistsInWhitelist: boolean[] = [];

    for (const campaignAddress of whitelistAddresses) {
      const status = whitelistedAddresses.includes(campaignAddress);
      campaignExistsInWhitelist.push(status);
    }

    return campaignExistsInWhitelist.some((exists: boolean) => exists);
  };

  return (
    <Modal title="Whitelist pool for transfer" onClose={(): void => onClose(false)}>
      <div className="modal-text">
        Stakes can only be transfered to another staking pool when it is whitelisted. The transfer can only happen after
        the lock period has expired, but it ignores the cooldown period. Be careful, once you have whitelisted a pool,
        you can not remove it from the whitelist.
      </div>

      <div className="modal-form">
        <div className="mb-24">
          <div className="text-bold">Whitelisted campaigns:</div>

          {!loadingWhitelisting ? (
            whitelistedAddresses.length > 0 ? (
              <div className="whitelisted-campaigns">
                {whitelistedAddresses.map((address, addressIndex) => {
                  return (
                    <div className="mt-4 text-sm" key={addressIndex}>
                      <span className="ml-8 mr-8">{address}</span>
                      <a
                        href={`${getExplorerAddressByChainId(chainId)}${address}`}
                        className="hyperlink-pool-address"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={Icons.open_link_light} />
                      </a>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className="mt-4 text-sm">No whitelisted campaigns</div>
            )
          ) : (
            <div className="flex mt-4">
              <img height={20} src={Icons.spinner_animated} alt="loader" />
              <span className="ml-4 text-sm">Loading whitelisted campaigns...</span>
            </div>
          )}
        </div>

        {whitelistAddresses.map((address, addressIndex) => (
          <TextField
            className="mb-24"
            key={addressIndex}
            value={address}
            label="Campaign address"
            type="text"
            icon="pool"
            onChange={(value): void => {
              const newAddresses = [...whitelistAddresses];
              newAddresses[addressIndex] = value;
              setWhitelistAddresses(newAddresses);
            }}
            onValidate={(address_: string): string => {
              if (whitelistedAddresses.includes(address_)) {
                return 'Campaign already whitelisted';
              } else if (!utils.isAddress(address_)) {
                return 'Please enter a valid address';
              }
              return '';
            }}
          />
        ))}

        <div className="flex flex-center mt-8">
          <div className="mr-8">
            <Button
              color="primary"
              size="small"
              onClick={(): void => setWhitelistAddresses([...whitelistAddresses, ''])}
              label="Add"
              icon="plus"
              iconposition="right"
              disabled={campaignExists() || whitelistAddresses.some((address) => !utils.isAddress(address))}
            />
          </div>

          <div>
            <Button
              color="primary"
              size="small"
              onClick={(): void =>
                setWhitelistAddresses([...whitelistAddresses.slice(0, whitelistAddresses.length - 1)])
              }
              label="Remove"
              icon="bin"
              iconposition="right"
              disabled={campaignExists() || whitelistAddresses.some((address) => !utils.isAddress(address))}
            />
          </div>
        </div>
      </div>

      <div className="modal-buttons">
        <Button
          color="primary"
          size="medium"
          onClick={(): void => {
            if (pool.version === PoolVersions.v1) addToWhitelistV1();
            if (pool.version === PoolVersions.v2 || pool.version === PoolVersions.v3) addToWhitelistV2();
          }}
          label="Confirm"
          iconposition="left"
          icon={whitelisting ? 'reload' : 'check'}
          disabled={whitelisting || campaignExists() || whitelistAddresses.some((address) => !utils.isAddress(address))}
          spin={whitelisting}
        />
      </div>
    </Modal>
  );
};
