import React from 'react';
import { Pool } from '../../../components/LiquidityMiningPool';
import { LiquidityMiningPoolList } from '../../../components/LiquidityMiningPoolList';

export const ScheduledPools: React.FC = () => {
  return (
    <LiquidityMiningPoolList
      title="Scheduled pools"
      subTitle="List of scheduled pools (new pools can take a few minutes to show up here)"
      filter={(pool: Pool): boolean => {
        return pool.start > new Date();
      }}
    />
  );
};
