import { BigNumber, Contract, ContractTransaction } from 'ethers';
import { loadContract } from './loadContract';
import { MultiSigWallet } from '@stichting-allianceblock-foundation/multisig-contracts/typechain/MultiSigWallet';
import { MULTISIG_DOES_NOT_EXIST } from '../constants/errors';

export const sendMultisig = async (contract: Contract, methodName: string, args: Array<any>): Promise<string> => {
  const wallet = loadContract('wallet') as MultiSigWallet;

  if (wallet === undefined) {
    throw new Error(MULTISIG_DOES_NOT_EXIST);
  }

  console.log(methodName, args);

  const transaction = contract.interface.encodeFunctionData(methodName, args);

  const initTx = await wallet.initiateTransaction(contract.address, BigNumber.from(0), transaction);

  const initReceipt = await initTx.wait();

  if (!initReceipt.events || !initReceipt.events[1] || !initReceipt.events[1].args) {
    throw new Error('No transaction id');
  }

  const transactionId = initReceipt.events[1].args.transactionId as BigNumber;

  return transactionId.toString();
};
