import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CreatePool } from '../../../components/CreatePool';
import { CreatePoolState } from '../../../utils/helpers';
import { WeiAmount } from '../../../components/WeiInput';
import { addDecimals } from '../../../sdk/helpers/addDecimals';
import { Duration } from '../../../sdk/helpers/duration';
import { TokenInformation } from '../../../sdk/helpers/getTokenInformation';
import { getNetworkName } from '../../../sdk/helpers/network';
import { StakingCampaignPayment } from '../../../sdk/staking-v2/StakingCampaignPayment';
import { networkConfigurations } from '../../../sdk/constants/networkConfig';
import { RewardsPoolBase } from '../../../sdk/staking-v2/RewardsPoolBase';
import { useNetwork } from 'wagmi';

export const CreateStakingPool: React.FC = () => {
  const { chain } = useNetwork();

  const history = useHistory();
  const [state, setState] = useState<CreatePoolState | undefined>(undefined);

  useEffect(() => {
    const s = history.location.state as any;
    if (s) {
      const rewardsPool = new RewardsPoolBase();
      rewardsPool
        .load(s.poolAddress)
        .then(() => {
          s.pool = rewardsPool;
          setState(s);
        })
        .catch(console.error);
    }
  }, [history]);

  const [network, setNetwork] = useState('');
  const [networkId, setNetworkId] = useState('');

  useEffect(() => {
    getNetworkName().then(setNetwork);
    setNetworkId(String(chain?.id));
  }, [chain?.id]);

  const createPool = async (
    protocol: string,
    tokenAddress: string,
    rewards: { info: TokenInformation; reward: WeiAmount }[],
    userStakingLimit: WeiAmount,
    stakingLimit: WeiAmount,
    throttleRoundDuration: Duration,
    throttleRoundCap: WeiAmount,
    tokenInformation: TokenInformation[],
    name: string,
  ): Promise<any> => {
    const campaign = new StakingCampaignPayment();
    await campaign.deploy(
      tokenAddress,
      rewards.map((reward) => reward.info.address),
      addDecimals(userStakingLimit.amount, userStakingLimit.decimals),
      throttleRoundDuration,
      addDecimals(throttleRoundCap.amount, throttleRoundCap.decimals),
      addDecimals(stakingLimit.amount, stakingLimit.decimals),
      name,
      networkConfigurations[networkId].PAYMENT_ADDRESS,
    );

    if (!campaign.contract) throw new Error('Deploy failed');

    await campaign.contract.deployed();

    const info = {
      type: 'createStakingCampaign',
      network: network,
      protocol: protocol,
      tokenAddresses: [tokenAddress],
      lpTokenAddress: tokenAddress,
      rewardsAddresses: rewards.map((reward) => reward.info),
      tokenInformation: tokenInformation,
    };

    const pool = new RewardsPoolBase();
    await pool.load(campaign.contract.address);

    return { pool, info };
  };

  return (
    <React.Fragment>
      <CreatePool
        title="New staking campaign"
        subTitle="When a user stakes their tokens in a staking campaign, their tokens are really locked for the remaining duration you set under campaign duration. After the campaign has ended, a cooldown period is in effect until the user can claim their tokens. After the cooldown period, users can take a maximum amount of tokens per day in order to avoid a rush of claimed tokens."
        tokenTitle="Staking token"
        tokenLabel="Token address"
        showProtocol={false}
        showThrottleRound={true}
        onCreatePool={createPool}
        rewardsLimit={1}
        singleSidedToken={true}
        type={2}
        state={state}
      />
    </React.Fragment>
  );
};
