import React, { useEffect, useState } from 'react';
import { useHistory, Link } from 'react-router-dom';
import axios from 'axios';
import { Icons } from '../../assets/icons';
import BadWordsFilter from 'bad-words';
import { ReactComponent as CopyIcon } from '../../assets/icons/copy-light.svg';
import { Button } from '../../components/Button';
import { Dropdown } from '../../components/Dropdown';
import { DurationInput } from '../DurationInput';
import { Popover } from '../../components/Popover';
import { TextField } from '../../components/TextField';
import { TokenInputField } from '../../components/TokenInputField';
import { ToggleSwitch } from '../../components/ToggleSwitch';
import { CoingeckoInputField } from '../../components/CoingeckoInputField';
import { getLPContractAddress, LPFinderAllowedProtocols } from '../../sdk/helpers/getLPContractAddress';
import { WeiAmount, WeiInput } from '../WeiInput';
import { getProtocolContract } from '../../sdk/helpers/getProtocolContract';
import { getProjectConfigAll, getProjectName } from '../../ducks/project/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { addSnackbar } from '../../ducks/snackbar/action';
import { BigNumber, utils } from 'ethers';
import { ethers } from 'ethers';
import { getTokenInformation, TokenInformation } from '../../sdk/helpers/getTokenInformation';
import { RewardsPoolBase } from '../../sdk/staking-v2/RewardsPoolBase';
import { addDecimals } from '../../sdk/helpers/addDecimals';
import { getPaymentCredits, checkIfDeployCampaignCreditsAvailable } from '../../sdk/helpers/getPaymentCredits';
import { calculateCampaignPaymentType } from '../../sdk/helpers/calculateCampaignPaymentType';
import { isWhitelisted, paymentUseCredit, getCampaignCredits } from '../../sdk/payment';
import { ModernStep, ModernStepButtons, ModernStepPanel, ModernStepper } from '../ModernStepper';
import { createDuration, Duration, durationToDays, durationToUnit, DurationUnits } from '../../sdk/helpers/duration';
import { getEthersWeb3Provider } from '../../ducks/ethers/web3/selectors';
import { getSignature } from '../../utils/getSignature';
import { checkRewardBalanceV2 } from '../../sdk/helpers/checkRewardBalance';
import { formatDate } from '../../utils/formatDate';
import { handleError } from '../../utils/handleError';
import { isAddress } from 'ethers/lib/utils';
import { integerRegex, numberRegex } from '../../sdk/constants/regex';
import {
  CoingeckoCurrencies,
  CoingeckoTokenInfo,
  getCoingeckoTokenInfo,
  getCoingeckoTokenPrice,
} from '../../sdk/helpers/coingecko';
import { formatTokens, formatTokenInput, upToDecimals } from '../../utils/formatNumber';
import { getTierOptions, tierTenant } from './getTierOptions';
import { ClipboardTooltip } from '../ClipboardTooltip';
import { fetchProject } from '../../ducks/project/action';
import {
  PoolVersion,
  PoolVersions,
  getNetworkAndProtocol,
  getProtocolOptions,
  CreatePoolProps,
  Reward,
  minimumPoolScheduleTime,
} from '../../utils/helpers';

import { useAccount, useNetwork, useProvider } from 'wagmi';

// Import Tippy
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { getNetworkId } from '../../sdk/helpers/network';

export const CreatePool: React.FC<CreatePoolProps> = (props) => {
  // use handlers
  const dispatch = useDispatch();

  const { address: wallet } = useAccount();
  const { chain } = useNetwork();
  const provider = useProvider();

  const history = useHistory();
  const web3 = useSelector(getEthersWeb3Provider);
  const config = useSelector(getProjectConfigAll);
  const projectName = useSelector(getProjectName);

  // bad word filter
  const bwFilter = new BadWordsFilter();

  // useState - pool version
  const [version, setVersion] = useState<PoolVersion>(PoolVersions.v3);

  // useState - basic pool form
  const [network, setNetwork] = useState('');
  const [protocol, setProtocol] = useState('');
  const [step, setStep] = useState(0);
  const [creatingPool, setCreatingPool] = useState(false);
  const [multisigAddress, setMultisigAddress] = useState<any>('');
  const [renderPage, setRenderPage] = useState<boolean>(false);

  // useState - pool basic attributes
  const [name, setName] = useState<string>('');
  const [campaignMessage, setCampaignMessage] = useState<string>('');
  const [tier, setTier] = useState<string>('default');
  const [stakingAddress, setStakingAddress] = useState('');

  // useState - lp finder
  const [helperTokenA, setHelperTokenA] = useState('');
  const [helperTokenB, setHelperTokenB] = useState('');
  const [isLPHelperChecked, setIsLPHelperChecked] = useState(true);

  // useState - helpers
  const [disabledDuration, setDisabledDuration] = useState(false);

  // useState - staking limits and throttle
  const [userStakingLimit, setUserStakingLimit] = useState<WeiAmount>({ amount: BigNumber.from(0), decimals: 18 });
  const [stakingLimit, setStakingLimit] = useState<WeiAmount>({ amount: BigNumber.from(0), decimals: 18 });
  const [isStakingLimitChecked, setIsStakingLimitChecked] = useState(false);
  const [throttleRoundDuration, setThrottleRoundDuration] = useState<Duration>(createDuration(0, DurationUnits.days));
  const [throttleRoundCap, setThrottleRoundCap] = useState<WeiAmount>({ amount: BigNumber.from(0), decimals: 18 });
  const [cooldownAlert, setCooldownAlert] = useState(false);
  const [cooldownWarning, setCooldownWarning] = useState<JSX.Element>(<></>);
  const [totalDuration, setTotalDuration] = useState<string>('0');

  // useState - rewards
  const [rewards, setRewards] = useState<Reward[]>([]);
  const [coinList, setCoinList] = useState<{ id: string; symbol: string; name: string }[]>([]);
  const [validInputCoingecko, setValidInputCoingecko] = useState<boolean>(false);

  // useState - schedule
  const [duration, setDuration] = useState<Duration>(createDuration(0, DurationUnits.days));
  const [startDate, setStartDate] = useState<Date>(new Date(Date.now() + 7200000));
  const [startValuesError, setStartValuesError] = useState<string | JSX.Element>('');
  const [startingPool, setStartingPool] = useState(false);

  // useState - payment credits states
  const [hasCredits, setHasCredits] = useState<number>(0);
  const [creditsShort, setCreditsShort] = useState<number>(0);
  const [creditsMedium, setCreditsMedium] = useState<number>(0);
  const [creditsLong, setCreditsLong] = useState<number>(0);
  const [whitelisted, setWhitelisted] = useState<boolean>(false);
  const [redirectInstructions, setRedirectInstructions] = useState<boolean>(false);

  const [pool, setPool] = useState<RewardsPoolBase | null>(null);
  const [tokenInformation, setTokenInformation] = useState<TokenInformation[]>([]);
  const [tokensNeedingIcon, setTokensNeedingIcon] = useState<{ [key: string]: boolean }>({});
  const [loadingTokenInformation, setLoadingTokenInformation] = useState(false);
  const [balanceNeeded, setBalanceNeeded] = useState('');
  const [projectTokenError, setProjectTokenError] = useState<boolean>(false);

  // useState - apy calculator
  const [isRewardsCalculatorChecked, setIsRewardsCalculatorChecked] = useState(false);
  const [calculatorTokenPrices, setCalculatorTokenPrices] = useState<string[]>([]);
  const [calculatorTokenInfos, setCalculatorTokenInfos] = useState<CoingeckoTokenInfo[]>([]);
  const [calculatorThrottleRoundDuration, setCalculatorThrottleRoundDuration] = useState<Duration>(
    createDuration(0, DurationUnits.days),
  );
  const [calculatorTargetLiquidities, setCalculatorTargetLiquidities] = useState<string[]>([]);
  const [calculatorTargetAPY, setCalculatorTargetAPY] = useState<string>('0');
  const [calculatorRewards, setCalculatorRewards] = useState<number[]>([]);

  // useEffect - first init checks
  //
  // retrieve basic data
  useEffect(() => {
    getNetworkId().then((id) => {
      const [network, protocol] = getNetworkAndProtocol(id);
      setNetwork(network);
      setProtocol(protocol);
      setMultisigAddress(
        config.multisig[network as 'eth' | 'bsc' | 'polygon' | 'avalanche' | 'ewc' | 'moonbeam' | 'songbird'],
      );
    });
    return (): void => {
      setNetwork('');
      setProtocol('');
      setMultisigAddress('');
      setTokensNeedingIcon({});
    };
  }, []);

  // coingecko list
  // fetch coin list for coingecko validations
  useEffect(() => {
    async function fetchCoinList(): Promise<void> {
      try {
        const response = await axios.get(`${process.env.REACT_APP_COINGECKO_API}/coins/list`);
        const { data } = response;
        setCoinList(data);
      } catch (error) {
        console.error(error);
      }
    }

    const someTokensNotFound = tokenInformation
      .map((token) => token.coingeckoFound)
      .some((coingeckoFound) => !coingeckoFound);

    const someRewardsNotFound = rewards
      .map((reward) => reward.info.coingeckoFound)
      .some((coingeckoFound) => !coingeckoFound);

    if (coinList.length === 0 && (someTokensNotFound || someRewardsNotFound)) {
      fetchCoinList();
    }
  }, [tokenInformation, rewards]);

  // payment credits
  // V3: check available payment credits, if not redirect to credits page
  // V2: skip that part and render the form
  useEffect(() => {
    if (multisigAddress) {
      if (props.state && props.state.version === PoolVersions.v2) {
        setRenderPage(true);
      } else {
        isWhitelisted(multisigAddress)
          .then((whitelistResult) => {
            setWhitelisted(whitelistResult);

            checkIfDeployCampaignCreditsAvailable(multisigAddress)
              .then((res) => {
                setRenderPage(true);
                if (!whitelistResult && !res) {
                  setRedirectInstructions(true);
                } else {
                  getCampaignCredits(multisigAddress).then((credits) => {
                    setCreditsShort(Number(credits[0]));
                    setCreditsMedium(Number(credits[1]));
                    setCreditsLong(Number(credits[2]));
                  });
                }
              })
              .catch((e) => {
                if (!whitelistResult) {
                  setRedirectInstructions(true);
                }

                setRenderPage(true);
              });
          })
          .catch((e) => {
            setRedirectInstructions(true);
            setRenderPage(true);
          });
      }
    }
  }, [multisigAddress]);

  // project token
  // check project token, if not redirect to configuration page
  // otherwise load details for first reward
  useEffect(() => {
    if (rewards.length === 0 && !history?.location?.state) {
      if (!config || !config.token?.address) setProjectTokenError(true);
      else {
        getTokenInformation({ chainId: chain?.id as number, tokenAddress: config.token?.address, provider }).then(
          (info) => {
            setRewards([
              {
                readOnly: true,
                info: info,
                reward: {
                  amount: BigNumber.from(0),
                  decimals: info.decimals ? info.decimals : 18,
                },
              },
            ]);
          },
        );
      }
    }
  }, [config]);

  // schedule campaign
  // load state data
  useEffect(() => {
    if (props.state) {
      setStep(props.state.step);
      setVersion(props.state.version);
      setName(props.state.name);
      setCampaignMessage(props.state.campaignMessage);
      setProtocol(props.state.protocol);
      setStakingAddress(props.state.stakingAddress);
      setUserStakingLimit(props.state.userStakingLimit);
      setStakingLimit(props.state.stakingLimit);

      props.state.rewards.map((reward, rewardIndex) => {
        getTokenInformation({ chainId: chain?.id as number, tokenAddress: reward.info.address, provider })
          .then((info) => {
            setRewards(
              (props?.state?.rewards as Reward[]).map((r, i) => {
                if (i === rewardIndex) r.info.coinGeckoID = info.coinGeckoID;
                return r;
              }),
            );
          })
          .catch((e) => {
            console.log(e);
          });
      });

      setThrottleRoundDuration(props.state.throttleRoundDuration);
      setThrottleRoundCap(props.state.throttleRoundCap);
      setPool(props.state.pool);

      // unlimited staking
      if (ethers.constants.MaxUint256.div(BigNumber.from(10).pow(18)).eq(props.state.userStakingLimit.amount)) {
        setIsStakingLimitChecked(true);
      }
      // limited staking
      else {
        setTotalDuration(
          Number(
            (Number(props.state.stakingLimit.amount) / Number(props.state.throttleRoundCap.amount)).toFixed(4),
          ).toString(),
        );
      }
    }
  }, [props.state]);

  // useEffect - apy calculator
  //
  // set target liquidity at first
  useEffect(() => {
    const loadTargetLiquidities = (): void => {
      const liquidities: string[] = [];
      for (let i = 0; i <= calculatorTokenPrices.length; i++) {
        if (calculatorTargetLiquidities[i]) {
          liquidities.push('0');
        } else {
          liquidities.push(calculatorTargetLiquidities[i]);
        }
      }
      setCalculatorTargetLiquidities(liquidities);
    };

    loadTargetLiquidities();
  }, [calculatorTokenPrices]);

  // Load token price in USD, load token icon URL and clear calculator on switch off
  useEffect(() => {
    const loadTokenPriceFromCoingecko = async (): Promise<void> => {
      if (isRewardsCalculatorChecked && rewards.length > 0) {
        const tokenPrices: string[] = [];
        for (let i = 0; i < rewards.length; i++) {
          if (rewards[i]?.info?.coinGeckoID) {
            try {
              // if we add a new reward and we had modified the value before adding it, we keep it the same value
              if (Number(calculatorTokenPrices[i]) > 0) {
                tokenPrices.push(calculatorTokenPrices[i]);
                continue;
              }
              const coingeckoId: string = rewards[i].info.coinGeckoID;
              const tokenPrice: number = await getCoingeckoTokenPrice(coingeckoId, CoingeckoCurrencies.USD);
              tokenPrices.push(String(tokenPrice));
            } catch (e: any) {
              tokenPrices.push('0');
              console.error('[Request failed] Something wrong with request', e);
            }
          }
        }

        setCalculatorTokenPrices(tokenPrices);
      }
    };

    const loadTokenInfosFromCoingecko = async (): Promise<void> => {
      if (isRewardsCalculatorChecked && rewards.length > 0) {
        const tokenInfos: CoingeckoTokenInfo[] = [];
        for (const reward of rewards) {
          if (reward?.info?.coinGeckoID) {
            try {
              const coingeckoId: string = reward.info.coinGeckoID;
              const tokenInfo: CoingeckoTokenInfo = await getCoingeckoTokenInfo(coingeckoId);
              tokenInfos.push(tokenInfo);
            } catch (e: any) {
              tokenInfos.push({} as CoingeckoTokenInfo);
              console.error('[Request failed] Something wrong with request', e);
            }
          }
        }

        setCalculatorTokenInfos(tokenInfos);
      }
    };

    const clearCalculatorOnSwitchOff = (): void => {
      if (!isRewardsCalculatorChecked) {
        setCalculatorRewards([]);
        setCalculatorTokenInfos([]);
        setCalculatorTokenPrices([]);
        setCalculatorTargetLiquidities([]);
        setCalculatorTargetAPY('0');
        setCalculatorThrottleRoundDuration({ value: 0, unit: DurationUnits.days });
      }
    };

    loadTokenPriceFromCoingecko();
    loadTokenInfosFromCoingecko();
    clearCalculatorOnSwitchOff();
  }, [isRewardsCalculatorChecked, rewards]);

  // Calculate Rewards
  useEffect(() => {
    const calculateRewards = (): void => {
      if (rewards.length > 0) {
        const rewardsCalculated: number[] = [];
        const rewardsLength: number = rewards.filter((reward) => reward?.info?.coinGeckoID).length;
        for (let i = 0; i < rewards.length; i++) {
          const targetLiquidity = Number(calculatorTargetLiquidities[i] ?? '0') * Number(calculatorTokenPrices[i]);
          const result =
            Number(calculatorTokenPrices[i]) > 0
              ? (targetLiquidity / rewardsLength / Number(calculatorTokenPrices[i])) *
                (Number(calculatorTargetAPY) / 100) *
                (durationToDays(calculatorThrottleRoundDuration) / 365)
              : 0;
          rewardsCalculated.push(result);
        }

        setCalculatorRewards(rewardsCalculated);
      }
    };

    calculateRewards();
  }, [
    calculatorTokenPrices,
    calculatorTargetAPY,
    calculatorTargetLiquidities,
    calculatorThrottleRoundDuration,
    rewards,
  ]);

  // useEffect - throttle values and cooldown
  //
  // exit throttle default values when staking limit option changed
  useEffect(() => {
    if (!props.state) {
      setTotalDuration('0');
      setThrottleRoundDuration(createDuration(0, DurationUnits.days));
      setThrottleRoundCap({
        amount: 0,
        decimals: throttleRoundCap.decimals,
      });
    }
  }, [isStakingLimitChecked]);

  // limited exit throttle calculations
  useEffect(() => {
    if (!props.state) {
      if (props.singleSidedToken && !isStakingLimitChecked) {
        setThrottleRoundDuration(
          createDuration(Number(totalDuration) >= 1 ? 1 : Number(totalDuration), DurationUnits.days),
        );
        if (stakingLimit.amount && Number(totalDuration) && Number(totalDuration) >= 1) {
          setThrottleRoundCap({
            amount:
              Number(stakingLimit.amount) / Number(totalDuration) < 0.00001
                ? 0.00001
                : Number(stakingLimit.amount) / Number(totalDuration) > 100
                ? Math.round(Number(stakingLimit.amount) / Number(totalDuration))
                : Number(stakingLimit.amount) / Number(totalDuration) > 1
                ? (Number(stakingLimit.amount) / Number(totalDuration)).toFixed(2)
                : (Number(stakingLimit.amount) / Number(totalDuration)).toFixed(5),
            decimals: throttleRoundCap.decimals,
          });
        } else if (stakingLimit.amount && Number(totalDuration) && Number(totalDuration) < 1) {
          setThrottleRoundCap({
            amount: Number(stakingLimit.amount),
            decimals: throttleRoundCap.decimals,
          });
        } else {
          setThrottleRoundCap({
            amount: 0,
            decimals: throttleRoundCap.decimals,
          });
        }
      }
    }
  }, [stakingLimit, totalDuration]);

  useEffect(() => {
    if (props.singleSidedToken) {
      // unlimited
      if (isStakingLimitChecked) {
        if (
          throttleRoundDuration.value > 0 &&
          integerRegex.test(throttleRoundCap.amount.toString()) &&
          BigNumber.from(throttleRoundCap.amount).gt(0)
        )
          renderCooldownWarning();
        else setCooldownWarning(<></>);
      }
      // limited
      else {
        if (
          Number(totalDuration) > 0 &&
          integerRegex.test(stakingLimit.amount.toString()) &&
          BigNumber.from(stakingLimit.amount).gt(0)
        )
          renderCooldownWarning();
        else setCooldownWarning(<></>);
      }
    } else {
      setCooldownWarning(<></>);
    }
  }, [isStakingLimitChecked, throttleRoundDuration, throttleRoundCap, userStakingLimit, stakingLimit]);

  // useEffect - helpers
  //
  // retrieve staking token decimals from contract
  useEffect(() => {
    const loadStakingDecimals = async (): Promise<void> => {
      if (utils.isAddress(stakingAddress)) {
        getTokenInformation({ chainId: chain?.id as number, tokenAddress: stakingAddress, provider }).then((info) => {
          setUserStakingLimit({
            ...userStakingLimit,
            decimals: info.decimals,
          });
          setStakingLimit({
            ...stakingLimit,
            decimals: info.decimals,
          });
          setThrottleRoundCap({
            ...throttleRoundCap,
            decimals: info.decimals,
          });
        });
      }
    };

    loadStakingDecimals();
  }, [stakingAddress]);

  // check pool rewards balance for pool deployment
  useEffect(() => {
    const checkBalance = async (): Promise<void> => {
      if (!pool || !pool.contract) return;

      const needed = await checkRewardBalanceV2(pool, rewards);
      setBalanceNeeded(needed);
    };

    checkBalance().catch(console.error);

    const intervalId = setInterval(() => {
      checkBalance().catch(console.error);
    }, 5000);

    return (): void => {
      clearInterval(intervalId);
    };
  }, [pool, network, rewards]);

  // Get tokens backing the LP token
  useEffect(() => {
    setLoadingTokenInformation(true);

    (async (): Promise<void> => {
      if (utils.isAddress(stakingAddress)) {
        try {
          const information: TokenInformation[] = [];

          if (props.singleSidedToken) {
            information.push(
              await getTokenInformation({ chainId: chain?.id as number, tokenAddress: stakingAddress, provider }),
            );
          }

          try {
            const poolTokenContract = await getProtocolContract(protocol, stakingAddress);
            const tokenAddresses = await poolTokenContract.getTokens();

            // remove LP token address from coinGeckoID input
            if (props.singleSidedToken) {
              information.pop();
            }

            for (const tokenAddress of tokenAddresses) {
              information.push(await getTokenInformation({ chainId: chain?.id as number, tokenAddress, provider }));
            }
          } catch (err) {
            if (!props.singleSidedToken) {
              setTokenInformation([]);
            }
          }
          setTokenInformation(information);
        } catch (err) {
          setTokenInformation([]);
        }
      } else {
        setTokenInformation([]);
      }

      setLoadingTokenInformation(false);

      if (!LPFinderAllowedProtocols.includes(protocol) && props.type == 2) setIsLPHelperChecked(true);
    })();
  }, [stakingAddress, protocol]);

  // lp finder
  useEffect(() => {
    async function fetchLpAddress(): Promise<void> {
      const lpAddress = await getLPContractAddress(protocol, helperTokenA, helperTokenB);

      if (lpAddress && isAddress(lpAddress) && !BigNumber.from(lpAddress).isZero()) {
        setStakingAddress(lpAddress);
      } else {
        if (helperTokenA && helperTokenB) {
          dispatch(
            addSnackbar({
              type: 'error',
              title: 'Not exists',
              content: `LP contract address not found on ${protocol} with given tokens.`,
            }),
          );
        }

        setStakingAddress('');
      }
    }

    fetchLpAddress();
  }, [helperTokenA, helperTokenB]);

  // check payment credit
  useEffect(() => {
    if (!whitelisted) {
      const intervalId = setTimeout(() => {
        (async (): Promise<void> => {
          if (multisigAddress) {
            setDisabledDuration(true);
            const res = await getPaymentCredits(multisigAddress, durationToUnit(duration, DurationUnits.days));
            setHasCredits(Number(res));
            setDisabledDuration(false);
          }
        })();
      }, 600);
      return (): void => {
        clearInterval(intervalId);
      };
    }
  }, [config, network, duration]);

  // show starting value errors
  useEffect(() => {
    setStartValuesError(validateStartValues());
  }, [hasCredits, duration, startDate, rewards, startDate]);

  // Definitions
  // render main form
  const renderDeployDetails = (readOnly: boolean): any => {
    return (
      <>
        <div className="pool-form-header">Optional campaign name</div>
        <TextField
          data-cy="create-lmc-name-field"
          value={name}
          label=""
          type="text"
          icon="symbol"
          onChange={(value): void => {
            setName(value);
          }}
          disabled={readOnly}
          onValidate={(value): string =>
            value === '' || (value.length > 0 && value.length <= 32) ? '' : 'Name must be between 1 and 32 characters'
          }
        />
        <div className="pool-form-header">Optional informational message</div>
        <TextField
          className="textArea"
          maxLength={150}
          textArea={true}
          value={campaignMessage}
          type="text"
          disabled={readOnly}
          onChange={(value): void => {
            setCampaignMessage(value);
          }}
        />

        {props.showProtocol && (
          <React.Fragment>
            <div className="pool-form-header">Exchange</div>
            <Dropdown
              value={protocol}
              label="Choose protocol"
              options={getProtocolOptions(network)}
              onChange={(value): void => setProtocol(value.toString())}
              disabled={readOnly || loadingTokenInformation}
            />
          </React.Fragment>
        )}

        {props.showTier && (
          <React.Fragment>
            <div className="pool-form-header">Tier</div>
            <Dropdown
              value={tier}
              label="Choose tier"
              options={getTierOptions()}
              onChange={(value): void => setTier(value.toString())}
              disabled={readOnly}
            />
          </React.Fragment>
        )}

        <div className="pool-form-header flex">
          {props.tokenTitle} address
          {!readOnly && LPFinderAllowedProtocols.includes(protocol) && props.type == 1 && (
            <div className={`toggleSwitch checked-${isLPHelperChecked}`}>
              <span>LP Address Finder</span>
              <ToggleSwitch
                onChange={(value: boolean): void => {
                  setIsLPHelperChecked(value);
                  setHelperTokenA('');
                  setHelperTokenB('');
                }}
                checked={isLPHelperChecked}
              />
              <span>Hide</span>
            </div>
          )}
        </div>
        {!readOnly && (
          <>
            <div className={`lpAddressFinder flex ${isLPHelperChecked ? 'hidden' : ''}`}>
              <TextField
                value={helperTokenA}
                label="Token A"
                type="text"
                icon="pool"
                className="w-100"
                onChange={(value): void => setHelperTokenA(value)}
                onValidate={(value): string => (isAddress(value) ? '' : 'Invalid address')}
              />
              <div className="space-20"></div>
              <TextField
                value={helperTokenB}
                label="Token B"
                type="text"
                icon="pool"
                className="w-100"
                onChange={(value): void => setHelperTokenB(value)}
                onValidate={(value): string => (isAddress(value) ? '' : 'Invalid address')}
              />
            </div>
          </>
        )}
        <TextField
          data-cy="staking-or-lp-token-address"
          value={stakingAddress}
          label={props.tokenLabel}
          type="text"
          icon="pool"
          disabled={!isLPHelperChecked || readOnly}
          onChange={(value): void => {
            setStakingAddress(value);
          }}
          onValidate={(value): string => (isAddress(value) ? '' : 'Invalid address')}
        />

        {tokenInformation.map((information, index) => {
          return (
            <div className="coingecko-input-field" key={index}>
              {information?.coingeckoFound ? (
                <TokenInputField
                  id="lpTokens"
                  name="rewardToken"
                  value={information.coinGeckoID}
                  iconUrl={information.icon}
                  tokenSymbol={information.symbol}
                  readOnly={true}
                  label="Coingecko ID"
                  type="text"
                  fileInputName="basic_setup_token_image"
                  onChangeFile={(): void => {
                    return;
                  }}
                  fileValue={''}
                  hideAddIcon={true}
                  className="mb-16"
                />
              ) : (
                <CoingeckoInputField
                  id="lpTokens"
                  name="rewardToken"
                  address={information.address}
                  tokenSymbol={information.symbol}
                  onChange={(coinGeckoID: string): void => {
                    const array = [...tokenInformation];
                    array[index].coinGeckoID = coinGeckoID;
                    setTokenInformation(array);
                    setTokensNeedingIcon({ ...tokensNeedingIcon, [information.name]: true });
                  }}
                  autocomplete={coinList}
                  title={'Coingecko ID'}
                  label={coinList ? 'Enter project name / token ticker' : 'Coingecko URL'}
                  required={true}
                  icon="discord_icon_input"
                  type="text"
                  fileInputName={`basic_setup_token_image${index}`}
                  onChangeFile={(): void => {
                    setTokensNeedingIcon({ ...tokensNeedingIcon, [information.name]: false });
                  }}
                  fileValue={''}
                />
              )}
            </div>
          );
        })}

        <div className="pool-form-header flex">
          Staking limits
          <div className={`toggleSwitch checked-${isStakingLimitChecked}`}>
            <span>Set Limit</span>
            <ToggleSwitch
              onChange={(value: boolean): void => {
                if (!readOnly) {
                  const maxAmount = ethers.constants.MaxUint256.div(BigNumber.from(10).pow(18));

                  setUserStakingLimit({
                    ...userStakingLimit,
                    amount: value ? maxAmount : BigNumber.from(0),
                  });

                  setStakingLimit({
                    ...stakingLimit,
                    amount: value ? maxAmount : BigNumber.from(0),
                  });

                  setIsStakingLimitChecked(value);
                }
              }}
              checked={isStakingLimitChecked}
              disabled={creatingPool}
            />
            <span>No Limit</span>
          </div>
        </div>
        <div className={`flex ${isStakingLimitChecked ? 'hidden' : ''}`}>
          <WeiInput
            disabled={readOnly}
            label="Limit per wallet"
            value={userStakingLimit}
            onChange={setUserStakingLimit}
            dataCy="create-campaign-limit-per-wallet-field"
          />
          <div className="space-20"></div>
          <WeiInput
            dataCy="create-campaign-total-limit-field"
            disabled={readOnly}
            label="Campaign limit"
            value={stakingLimit}
            onChange={setStakingLimit}
          />
        </div>

        {props.showThrottleRound && (
          <React.Fragment>
            {isStakingLimitChecked ? (
              <>
                <div className="pool-form-header exit-round-duration split">
                  <div>
                    Cooldown Duration{' '}
                    <Popover
                      content={`The Cooldown Duration represents the length of time during which the staked tokens will be unlocked continuously for your campaign participants,
                      with a set amount of tokens released per round. The total duration is defined by the number of days and the amount of tokens unlocked per round.
                      Withdrawal of the tokens is on a first-come, first-served basis.
                      Users who withdraw early will be able to claim their tokens earlier.`}
                    >
                      <img className="popover-info" src={Icons.info} />
                    </Popover>
                  </div>
                </div>

                <div className="pool-form-campaign-duration exit-duration split">
                  <DurationInput
                    readOnly={readOnly}
                    dataCy="create-campaign-exit-round-duration"
                    value={throttleRoundDuration}
                    onChange={(value: Duration): void => setThrottleRoundDuration(value)}
                    label="Length of round"
                  />
                  <WeiInput
                    disabled={readOnly}
                    dataCy="create-campaign-exit-round-limit"
                    label="Limit per round"
                    value={throttleRoundCap}
                    onChange={setThrottleRoundCap}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="pool-form-header exit-round-duration">
                  Cooldown Duration{' '}
                  <Popover
                    content={`The Cooldown Duration represents the number of days during which the staked tokens will be unlocked continuously for your campaign participants.
                    Withdrawal of the tokens is on a first-come, first-served basis.
                    Users who withdraw early will be able to claim their tokens earlier.`}
                  >
                    <img className="popover-info" src={Icons.info} />
                  </Popover>
                </div>
                <div className="pool-form-campaign-duration exit-duration split">
                  <TextField
                    className="mr-8 spacer"
                    type="number"
                    icon="clock"
                    label="Number of days"
                    min="0"
                    value={totalDuration}
                    onChange={setTotalDuration}
                    onValidate={(value: string): string => {
                      if (!numberRegex.test(value)) return 'Please enter a valid number';
                      return '';
                    }}
                    disabled={readOnly}
                  />
                </div>
              </>
            )}
            {!readOnly && cooldownWarning}
            {!readOnly && cooldownAlert && (
              <>
                <div className="cooldown-alert-container">
                  <div className="cooldown-alert-overlay" />
                  <div className="cooldown-alert-content">
                    <div className="cooldown-alert-title">Warning!</div>
                    <div className="cooldown-alert-text">
                      Your participants may have to wait for a large amount of time to claim their tokens. With the
                      current values,{' '}
                      <strong>
                        {integerRegex.test(throttleRoundCap.amount.toString()) &&
                        BigNumber.from(throttleRoundCap.amount).gt(0)
                          ? formatTokens(throttleRoundCap.amount, 0)
                          : Number(throttleRoundCap.amount)}
                      </strong>{' '}
                      token(s) can be claimed every{' '}
                      <strong>{isStakingLimitChecked ? throttleRoundDuration.value : totalDuration}</strong> day(s). Are
                      you sure you want to continue?
                    </div>
                    <div className="cooldown-alert-buttons">
                      <Button
                        color="primary"
                        onClick={(): void => {
                          setCooldownAlert(false);
                          createPool();
                        }}
                        size="large"
                        label="Yes"
                        icon="check"
                        iconposition="right"
                      />
                      <Button
                        color="process"
                        onClick={(): void => {
                          setThrottleRoundDuration(createDuration(0, DurationUnits.days));
                          setCooldownAlert(false);
                          setTotalDuration('0');
                        }}
                        size="large"
                        label="No"
                        icon="times"
                        iconposition="right"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
          </React.Fragment>
        )}

        <div className="pool-form-header flex">
          Reward tokens
          <div className="toggleSwitch">
            <span>Rewards calculator</span>
            <ToggleSwitch
              onChange={(value: boolean): void => {
                if (!readOnly) {
                  setIsRewardsCalculatorChecked(value);
                }
              }}
              checked={isRewardsCalculatorChecked}
              disabled={creatingPool}
            />
          </div>
        </div>
        {rewards.map((reward, rewardIndex) => (
          <div className="pool-form-reward" key={rewardIndex}>
            <TokenInputField
              id="rewardToken"
              name="rewardToken"
              value={reward.info.address}
              readOnly={readOnly || reward.readOnly}
              onChange={(e): void => setRewardAddress(rewardIndex, e.target.value)}
              label="Token address"
              required={true}
              icon="discord_icon_input"
              type="text"
              fileInputName="basic_setup_token_image"
              onChangeFile={(): void => {
                return;
              }}
              fileValue={''}
              hideAddIcon={true}
              className="mb-16"
            />
            {reward.info?.coingeckoFound ? (
              <TokenInputField
                id="coingeckoId"
                name="coingeckoId"
                value={reward.info.coinGeckoID}
                iconUrl={reward.info.icon}
                tokenSymbol={reward.info.symbol}
                readOnly={readOnly || reward.readOnly}
                label="Coingecko ID"
                type="text"
                fileInputName="basic_setup_token_image"
                onChangeFile={(): void => {
                  return;
                }}
                fileValue={''}
                hideAddIcon={true}
                className="mb-16"
              />
            ) : (
              <CoingeckoInputField
                id="rewardToken"
                name="rewardToken"
                address={reward.info.address}
                tokenSymbol={reward.info.symbol}
                onChange={(coinGeckoID: string): void => {
                  setRewards(
                    rewards.map((r, i) => {
                      if (i === rewardIndex) r.info.coinGeckoID = coinGeckoID;
                      return r;
                    }),
                  );
                }}
                autocomplete={coinList}
                label={coinList ? 'Type to search from Coingecko' : 'Coingecko URL'}
                required={true}
                icon="discord_icon_input"
                type="text"
                fileInputName={`basic_setup_reward_token_image${rewardIndex}`}
                onChangeFile={(): void => {
                  return;
                }}
                onError={(error): void => setValidInputCoingecko(error)}
                fileValue={''}
                disabled={readOnly}
              />
            )}
          </div>
        ))}
        {isRewardsCalculatorChecked && (
          <div className="rewards-calculator-form mt-8 mb-24">
            <div className="title mt-4">Rewards calculator</div>
            <div className="mt-8 mb-24">
              Change the target APY, duration and target liquidity to define the necessary amount of reward tokens.
            </div>
            {rewards.map((reward: Reward, index: number): JSX.Element | '' => {
              return (
                reward?.info?.coinGeckoID &&
                calculatorTokenInfos[index]?.id && (
                  <div className="flex mb-24" key={index}>
                    <div className="flex">
                      <TextField
                        type="number"
                        label={`${calculatorTokenInfos[index]?.symbol.toUpperCase()} Target liquidity ( tokens )`}
                        icon="pool"
                        min="0"
                        value={formatTokenInput(calculatorTargetLiquidities[index] ?? '0')}
                        onChange={(value: string): void => setCalculatorTargetLiquidityAt(index, value)}
                        rightComponent={
                          <img
                            className="mr-4"
                            src={calculatorTokenInfos[index]?.image.thumb}
                            alt={calculatorTokenInfos[index]?.name}
                          />
                        }
                        readOnly={readOnly}
                      />
                    </div>
                  </div>
                )
              );
            })}
            <div className="flex mt-24">
              <TextField
                type="number"
                label="Target APY"
                icon="percent"
                min="0"
                value={formatTokenInput(calculatorTargetAPY)}
                onChange={(value: string): void => setCalculatorTargetAPY(value)}
                rightComponent={<span className="text-secondary text-md mr-8">%</span>}
                readOnly={readOnly}
              />
              <div className="space-20"></div>
              <div className="spacer"></div>
            </div>
            <div className="pool-form-campaign-duration mt-24">
              <DurationInput
                readOnly={readOnly}
                dataCy="create-campaign-exit-round-duration"
                value={calculatorThrottleRoundDuration}
                onChange={(value: Duration): void => setCalculatorThrottleRoundDuration(value)}
              />
            </div>
            <div className="line"></div>
            <div className="title mt-24">Reward tokens amount</div>
            <div>
              {rewards.map((reward: Reward, index: number): JSX.Element | '' => {
                return (
                  reward?.info?.coinGeckoID &&
                  calculatorTokenInfos[index]?.id && (
                    <div className="flex mt-8" key={index}>
                      <span className="text-bold mr-8">{upToDecimals(calculatorRewards[index], 2)}</span>
                      <div className="flex">
                        <img
                          className="mr-4"
                          src={calculatorTokenInfos[index]?.image.thumb}
                          alt={calculatorTokenInfos[index]?.name}
                        />
                        <span>{calculatorTokenInfos[index]?.symbol.toUpperCase()}</span>
                      </div>
                    </div>
                  )
                );
              })}
            </div>
          </div>
        )}

        {!readOnly && props.rewardsLimit > 1 && (
          <div className="pool-form-rewards-add">
            <Tippy
              placement="bottom"
              disabled={rewards.length < props.rewardsLimit}
              content={`You have reached the maximum number (${props.rewardsLimit}) of reward tokens, and can't add any more.`}
            >
              <span>
                <Button
                  disabled={rewards.length == props.rewardsLimit}
                  color="primary"
                  size="small"
                  onClick={addReward}
                  label="Add reward"
                  icon="plus"
                  iconposition="right"
                />
              </span>
            </Tippy>

            {rewards.length > 1 && (
              <Button
                color="primary"
                size="small"
                onClick={(): void => {
                  setRewards(rewards.slice(0, rewards.length - 1));
                }}
                label="Remove reward"
                icon="bin"
                iconposition="right"
              />
            )}
          </div>
        )}

        {!readOnly && validateStakingValues().length > 0 && (
          <div className="pool-form-error">{validateStakingValues()}</div>
        )}
        {!readOnly && (
          <ModernStepButtons>
            <Tippy
              placement="bottom"
              disabled={config.tokens[network]?.address !== undefined}
              content={`Please add token address of current network (${network}) in Configuration`}
            >
              <>
                {(version === PoolVersions.v2 || version === PoolVersions.v3) && (
                  <Button
                    data-cy="create-campaigns-deploy-pool-button"
                    color="primary"
                    size="medium"
                    onClick={checkCooldownDurationAlert}
                    label="Deploy pool"
                    iconposition="right"
                    icon={creatingPool ? 'reload' : 'check'}
                    disabled={creatingPool || validateStakingValues().length > 0}
                    spin={creatingPool}
                  />
                )}
              </>
            </Tippy>
          </ModernStepButtons>
        )}
      </>
    );
  };

  // apy calculator
  const setCalculatorTargetLiquidityAt = (index: number, newLiquidity: string): void => {
    const newLiquidities: string[] = calculatorTargetLiquidities.map((liquidity: string, _index: number) =>
      index === _index ? newLiquidity : liquidity,
    );
    setCalculatorTargetLiquidities(newLiquidities);
  };

  // rewards
  const setRewardAddress = (rewardIndex: number, rewardAddress: string): void => {
    setRewards(
      rewards.map((r, i) => {
        if (i === rewardIndex) r.info.address = rewardAddress;
        return r;
      }),
    );
    getTokenInformation({ chainId: chain?.id as number, tokenAddress: rewardAddress, provider })
      .then((info) => {
        setRewards(
          rewards.map((r, i) => {
            if (i === rewardIndex) {
              r.info = info;
              r.reward.decimals = info.decimals;
            }
            return r;
          }),
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const addReward = (): void => {
    setRewards([
      ...rewards,
      {
        readOnly: false,
        info: {
          network: '',
          address: '',
          symbol: '',
          name: '',
          decimals: 18,
          coinGeckoID: '',
          projectToken: false,
        },
        reward: {
          amount: BigNumber.from(0),
          decimals: 18,
        },
      },
    ]);
  };

  // cooldown and throttle values
  const checkCooldownDurationAlert = (): void => {
    if (
      (isStakingLimitChecked && throttleRoundDuration.value > 5) ||
      (!isStakingLimitChecked && Number(totalDuration) > 30)
    ) {
      setCooldownAlert(true);
    } else {
      createPool();
    }
  };

  const renderCooldownWarning = (): void => {
    let message: JSX.Element = <></>;

    // if unlimited stake
    if (isStakingLimitChecked) {
      message = (
        <>
          <strong> {formatTokens(throttleRoundCap.amount, 0)}</strong> tokens(s) can be claimed every{' '}
          <strong>{throttleRoundDuration.value}</strong> day(s)
        </>
      );
    }
    // if limited stake
    else {
      message = (
        <>
          <strong>
            {integerRegex.test(throttleRoundCap.amount.toString()) && BigNumber.from(throttleRoundCap.amount).gt(0)
              ? formatTokens(throttleRoundCap.amount, 0)
              : Number(throttleRoundCap.amount)}
          </strong>{' '}
          token(s) can be claimed per {Number(totalDuration) < 1 && <strong>{totalDuration}</strong>} day
        </>
      );
    }

    setCooldownWarning(<div className="cooldown-warning-message">{message}</div>);
  };

  // validations
  const validateCoingeckoId = (coingeckoId: string): boolean => {
    if (coinList.length === 0) return false;
    if (!validInputCoingecko) return false;

    const results = coinList.filter((item) => item.id === coingeckoId);

    if (results.length > 0) return true;

    return false;
  };

  const validateStakingValues = (): string => {
    try {
      if (!(name === '' || (name !== '' && name.length > 0 && name.length <= 32))) return 'Invalid name';
      if (bwFilter.isProfane(name)) return 'You cannot use profane words in your campaign name';
      if (bwFilter.isProfane(campaignMessage))
        return 'You cannot use profane words in your optional informational message';
      if (!stakingAddress) return '';
      if (!isAddress(stakingAddress))
        return props.singleSidedToken ? 'Invalid staking address' : 'Invalid LP token address';
      if (tokenInformation.length === 0)
        return props.singleSidedToken
          ? 'Staking address is not a valid ERC20 token'
          : 'LP token is not a valid swap pair contract';
      if (!integerRegex.test(userStakingLimit.amount.toString()) || !BigNumber.from(userStakingLimit.amount).gt(0))
        return 'Invalid user staking limit';
      if (!integerRegex.test(stakingLimit.amount.toString()) || !BigNumber.from(stakingLimit.amount).gt(0))
        return 'Invalid staking limit';
      if (
        !(
          !props.showThrottleRound ||
          (durationToUnit(throttleRoundDuration, DurationUnits.seconds) > 0 && throttleRoundCap.amount > 0)
        )
      )
        return 'Invalid cooldown values';
      if (!rewards.every((reward) => isAddress(reward.info.address))) return 'Invalid rewards';
      if (
        projectName !== tierTenant &&
        !rewards.every((reward) => (reward.info.coingeckoFound ? true : validateCoingeckoId(reward.info.coinGeckoID)))
      )
        return 'Invalid Coingecko URL';
      if (
        projectName !== tierTenant &&
        !tokenInformation.every((tokenInfo, index) =>
          tokenInfo.coingeckoFound ? true : validateCoingeckoId(tokenInfo.coinGeckoID),
        )
      )
        return 'Invalid Coingecko URL';

      if (Object.values(tokensNeedingIcon).some((value) => value)) return 'No icon added';

      return '';
    } catch (e) {
      console.error(e);
      return 'There was an error while validation input values';
    }
  };

  const validateStartValues = (): string | JSX.Element => {
    try {
      if (startDate.getTime() - Date.now() < 3600000)
        return 'Start time is too close, needs to be at least 1 hour from now';
      if (durationToUnit(duration, DurationUnits.seconds) <= 0) return 'Duration needs to be greater than 0';
      if (version === PoolVersions.v3 && hasCredits <= 0 && !whitelisted) {
        return (
          <div>
            You need {calculateCampaignPaymentType(durationToUnit(duration, DurationUnits.days)).toLowerCase()} campaign
            credit to schedule your campaign. Go to the <Link to="/credits">credits</Link> page to buy a credit.
          </div>
        );
      }
      if (
        !rewards.every(
          (reward) => integerRegex.test(reward.reward.amount.toString()) && BigNumber.from(reward.reward.amount).gt(0),
        )
      )
        return 'Rewards needs to be greater than 0';

      return '';
    } catch (e) {
      console.error(e);
      return 'There was an error while validating input values';
    }
  };

  // deploy pool contract - step 1
  const createPool = (): void => {
    setCreatingPool(true);

    handleError(
      props
        .onCreatePool(
          protocol,
          stakingAddress,
          rewards,
          userStakingLimit,
          stakingLimit,
          throttleRoundDuration,
          throttleRoundCap,
          tokenInformation,
          name,
          campaignMessage,
        )
        .then((result: { pool: RewardsPoolBase; info: any }) => {
          setPool(result.pool);

          return (async (): Promise<void> => {
            const { signature, timestamp } = await getSignature(web3, dispatch);

            await axios.post(process.env.REACT_APP_API + '/project/add-campaign', {
              wallet: await web3?.signer.getAddress(),
              signature: signature,
              timestamp,
              deployedContractAddress: result.pool?.contract?.address,
              version: PoolVersions.v3,
              // backend does not allow empty strings
              // when we set to undefined, it won't be included to JSON.stringfy
              campaignMessage: campaignMessage || undefined,
              ...result.info,
            });

            await result.pool.transferOwnerShipToMultisig();

            setStep(step + 1);
          })();
        }),
      dispatch,
      'Pool creation',
      'The pool has been deployed succesfully',
    ).finally(() => {
      setCreatingPool(false);
      dispatch(fetchProject());
    });
  };

  // start pool - step 2
  const startPool = async (): Promise<void> => {
    if (!pool || !pool.contract) return;

    if ((await checkRewardBalanceV2(pool, rewards)).length > 0) {
      dispatch(
        addSnackbar({
          type: 'info',
          title: 'Pool creation failed',
          content: 'Not enough rewards in pool',
        }),
      );
      return;
    }

    setStartingPool(true);

    //if scheduling v2 campaign
    if (props.state && props.state.version === PoolVersions.v2) {
      handleError(
        pool.start(
          Math.floor(startDate.getTime() / 1000),
          Math.floor(startDate.getTime() / 1000) + durationToUnit(duration, DurationUnits.seconds),
          rewards.map((reward) => addDecimals(reward.reward.amount, reward.reward.decimals)),
        ),
        dispatch,
        'Pool creation',
        'The pool is now awaiting multisig confirmation',
        () =>
          setTimeout(() => {
            history.push('/multisig/transactions');
          }, 1000),
      ).finally(() => {
        setStartingPool(false);
      });
    } else {
      handleError(
        paymentUseCredit(
          Math.floor(startDate.getTime() / 1000),
          Math.floor(startDate.getTime() / 1000) + durationToUnit(duration, DurationUnits.seconds),
          rewards.map((reward) => addDecimals(reward.reward.amount, reward.reward.decimals)),
          pool.contract.address,
        ),
        dispatch,
        'Pool creation',
        'The pool is now awaiting multisig confirmation',
        async () => {
          const { signature, timestamp } = await getSignature(web3, dispatch);

          await axios.post(process.env.REACT_APP_API + '/project/add-duration', {
            wallet: await web3?.signer.getAddress(),
            signature: signature,
            campaignAddress: pool.contract?.address,
            timestamp,
            period: `${duration.value}-${duration.unit}`,
          });
          setTimeout(() => {
            history.push('/multisig/transactions');
          }, 1000);
        },
      ).finally(() => {
        setStartingPool(false);
        dispatch(fetchProject());
      });
    }
  };

  return (
    <React.Fragment>
      <div className="admins-section">
        <h3>{props.title}</h3>
        {props.type == 2 ? (
          <Popover
            content={`Staking contracts are deployed without the need of a protocol or liquidity pool (like Uniswap).`}
          >
            <img className="admins-section-info" src={Icons.info} />
          </Popover>
        ) : null}
      </div>
      <div>{props.subTitle}</div>
      {renderPage ? (
        <>
          {projectTokenError && (
            <>
              <div className="pool-form-error">
                You don&apos;t have a project token for this network.
                <br />
                Please enter your project token address at the <Link to="/configuration">configuration</Link> page.
              </div>
            </>
          )}

          {redirectInstructions ? (
            <>
              <div className="pool-form-error">
                To deploy a campaign you need to buy credits first. Please go to the <Link to="/credits">credits</Link>{' '}
                page to buy credits.
              </div>
            </>
          ) : (
            <>
              <h3 className="credits-title">Available Credits</h3>
              <div className="credits-info">
                <div>
                  <h5 className="m-0 mb-16">Short Campaign</h5>
                  <div className="available-credits">
                    <strong>{creditsShort}</strong> Credit(s)
                  </div>
                </div>
                <div>
                  <h5 className="m-0 mb-16">Medium Campaign</h5>
                  <div className="available-credits">
                    <strong>{creditsMedium}</strong> Credit(s)
                  </div>
                </div>
                <div>
                  <h5 className="m-0 mb-16">Long Campaign</h5>
                  <div className="available-credits">
                    <strong>{creditsLong}</strong> Credit(s)
                  </div>
                </div>
              </div>
            </>
          )}
          {!projectTokenError && !redirectInstructions && (
            <ModernStepper>
              <ModernStep
                stepNumber={0}
                activeStep={step}
                title={'Deploy contract'}
                subtitle={'Deploy your campaign contract'}
              >
                <ModernStepPanel>{renderDeployDetails(false)}</ModernStepPanel>
              </ModernStep>

              <ModernStep
                stepNumber={1}
                activeStep={step}
                title={'Schedule start'}
                subtitle={'Schedule the start of your campaign'}
              >
                <ModernStepPanel>
                  <>
                    <div className="pool-form-summary">{renderDeployDetails(true)}</div>
                    <div className="pool-form-header">Start time</div>
                    <div className="pool-form-campaign-duration localtime-field">
                      <TextField
                        data-cy="create-campaigns-start-timestamp-field"
                        label="Start timestamp"
                        value={formatDate(startDate)}
                        icon="calendar_black"
                        onChange={(value): void => (value ? setStartDate(new Date(value)) : undefined)}
                        type="datetime-local"
                        min={minimumPoolScheduleTime}
                      />
                    </div>

                    <div className="pool-form-header">Duration</div>
                    <div className="pool-form-campaign-duration">
                      <DurationInput
                        value={duration}
                        readOnly={disabledDuration}
                        onChange={setDuration}
                        dataCy="create-campaigns-duration-field"
                      />
                    </div>
                    <div className="pool-form-header">Reward amounts</div>
                    {rewards.map((reward, rewardIndex) => (
                      <div className="pool-form-reward" key={rewardIndex}>
                        <WeiInput
                          label={reward.info.symbol + ' reward'}
                          value={reward.reward}
                          onChange={(value): void => {
                            setRewards(
                              rewards.map((r, i) => {
                                if (i === rewardIndex) r.reward = value;
                                return r;
                              }),
                            );
                          }}
                          dataCy="create-campaigns-rewards-field"
                        />
                      </div>
                    ))}

                    {startValuesError && <div className="pool-form-error">{startValuesError}</div>}

                    {validateStakingValues().length === 0 && !startValuesError && balanceNeeded.length > 0 && (
                      <div className="pool-form-info">
                        There are not enough rewards in the contract, you need to send {balanceNeeded}&nbsp;to{' '}
                        <span data-cy="create-campaigns-tokens-needed-address-field">{pool?.contract?.address}</span>{' '}
                        <ClipboardTooltip content={pool?.contract?.address || ''}>
                          <CopyIcon className="copy-icon" />
                        </ClipboardTooltip>
                      </div>
                    )}

                    <ModernStepButtons>
                      <>
                        {(version === PoolVersions.v2 || version === PoolVersions.v3) && (
                          <Button
                            data-cy="create-campaigns-deploy-campaign-button"
                            color="primary"
                            size="medium"
                            onClick={startPool}
                            label="Confirm"
                            icon={startingPool ? 'reload' : 'check'}
                            disabled={
                              disabledDuration ||
                              !pool ||
                              startingPool ||
                              validateStakingValues().length > 0 ||
                              !!startValuesError ||
                              balanceNeeded.length > 0
                            }
                            spin={startingPool}
                            iconposition="right"
                          />
                        )}
                      </>
                    </ModernStepButtons>
                  </>
                </ModernStepPanel>
              </ModernStep>
            </ModernStepper>
          )}
        </>
      ) : (
        <div className="new-campaign-loading">Loading...</div>
      )}
    </React.Fragment>
  );
};
