import { BigNumber } from 'ethers';
import { Pool, LMReward, LockingPeriod } from '.';
import { blocksToDuration, blockToDate } from '../../sdk/helpers/duration';
import { getCurrentBlock } from '../../sdk/helpers/getCurrentBlock';
import { getProtocolContract } from '../../sdk/helpers/getProtocolContract';
import { getSchemeContract } from '../../sdk/helpers/getSchemeContract';
import { loadERC20 } from '../../sdk/helpers/loadERC20';
import { getNetworkConfig } from '../../sdk/helpers/network';
import { LMInterface } from '../../types/dto/Project.dto';
import { PoolVersions } from '../../utils/helpers';
import { RewardsPoolBase as RewardsPoolBaseV1 } from '../../sdk/staking-v1/RewardsPoolBase';

export async function loadV1(pool: LMInterface): Promise<Pool> {
  const poolContract = new RewardsPoolBaseV1();
  await poolContract.load(pool.campaignAddress);

  const networkConfig = await getNetworkConfig();

  const [
    lpAmount,
    rewardTokens,
    startBlock,
    endBlock,
    stakingToken,
    isDone,
    contractStakeLimit,
    stakeLimit,
  ] = await Promise.all([
    poolContract.getTotalStaked(),
    poolContract.getRewardTokens(),
    poolContract.getStartBlock(),
    poolContract.getEndBlock(),
    poolContract.getStakingToken(),
    poolContract.isDone(),
    poolContract.getContractStakeLimit(),
    poolContract.getStakeLimit(),
  ]);

  const durationInBlocks = endBlock.sub(startBlock).toNumber();
  const currentBlock = await getCurrentBlock();
  const remainingBlocks = endBlock.sub(currentBlock).toNumber();

  const secondsPerWeek = 604800;
  const blocksPerWeek = Math.floor(secondsPerWeek / networkConfig.BLOCK_TIME);
  const campaignMessage = pool.campaignMessage || '';

  // Rewards

  const rewardPromises: Promise<any>[] = [];

  for (let i = 0; i < rewardTokens.length; i++) {
    const rewardContract = loadERC20(rewardTokens[i]);
    rewardPromises.push(poolContract.getRewardPerBlock(i), rewardContract.getSymbol(), rewardContract.getDecimals());
  }

  const rewardResults: any[] = await Promise.all(rewardPromises);

  const campaignRewards: LMReward[] = [];
  for (let i = 0; i < rewardTokens.length; i++) {
    const rewardPerBlock = rewardResults[i * 3] as BigNumber;
    campaignRewards.push({
      symbol: rewardResults[i * 3 + 1] as string,
      address: rewardTokens[i],
      decimals: rewardResults[i * 3 + 2] as number,
      rewardPerBlock: rewardPerBlock,
      totalRewards: BigNumber.from(rewardPerBlock).mul(durationInBlocks),
      weeklyRewards: BigNumber.from(rewardPerBlock).mul(blocksPerWeek),
      remainingRewardAmount:
        remainingBlocks > 0 ? BigNumber.from(rewardPerBlock).mul(remainingBlocks) : BigNumber.from(0),
    });
  }

  // Balances

  const poolTokenContract = await getProtocolContract(pool.dex, stakingToken);

  const [balances, lpSupply, tokenAddresses] = await Promise.all([
    poolTokenContract.getReserves(),
    poolTokenContract.getTotalSupply(),
    poolTokenContract.getTokens(),
  ]);

  const tokens = [];

  /* this is only for mock porpuses, because
    we don't actually want to show
    the excess rewards on v1 */
  const rewards: any = [];

  for (let i = 0; i < tokenAddresses.length; i++) {
    const tokenAddress = tokenAddresses[i];
    const tokenContract = loadERC20(tokenAddress);

    tokens.push({
      symbol: await tokenContract.getSymbol(),
      balance: lpAmount.mul(balances[i]).div(lpSupply),
      excess: BigNumber.from(0),
      address: tokenAddress,
    });
  }

  // Lock schemes

  const savedLockingPeriods: LockingPeriod[] = [];
  if (pool.lockSchemeAddress) {
    const lockSchemeContract = await getSchemeContract(pool.lockSchemeAddress);
    const bonusPercent = (await lockSchemeContract.bonusPercent()) / 1000;

    savedLockingPeriods.push({
      duration: await blocksToDuration((await lockSchemeContract.lockPeriod()).toNumber()),
      bonus: bonusPercent,
      increaseDuration: await blocksToDuration((await lockSchemeContract.rampUpPeriod()).toNumber()),
      noLock: bonusPercent === 0,
    });
  }

  return {
    address: pool.campaignAddress,
    lpAddress: stakingToken,
    assets: tokens,
    rewards: rewards,
    protocol: pool.dex,
    lpTokenAmount: lpAmount,
    start: await blockToDate(startBlock.toNumber()),
    end: await blockToDate(endBlock.toNumber()),
    done: isDone,
    savedLockingPeriods,
    campaignRewards,
    version: PoolVersions.v1,
    name: '',
    campaignMessage: campaignMessage,
    totalLimit: contractStakeLimit,
    walletLimit: stakeLimit,
  };
}
