import { BigNumberish } from 'ethers';
import React from 'react';
import { Icons } from '../../assets/icons';
import { formatTokens } from '../../utils/formatNumber';

export const PoolAsset: React.FC<{
  asset: {
    address: string;
    balance: BigNumberish;
    symbol: string;
    image?: string;
    decimals?: number;
  };
}> = ({ asset }) => {
  return (
    <div className="pool-asset-amount flex">
      {asset.decimals ? (
        <div className="pool-asset-amount-number">{formatTokens(asset.balance, asset.decimals)}</div>
      ) : (
        <div className="pool-asset-amount-number">{formatTokens(asset.balance)}</div>
      )}
      <img
        className="pool-asset-coin-icon"
        src={
          asset.image ||
          (asset.address ? `${process.env.REACT_APP_ASSETS}/${asset.address.toLocaleLowerCase()}` : Icons.unknown_coin)
        }
      />
      <div className="pool-asset-amount-symbol">{asset.symbol.toUpperCase()}</div>
    </div>
  );
};
