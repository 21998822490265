import { BigNumber } from 'ethers';
import { Pool } from '.';
import { createDuration, DurationUnits, durationToUnit } from '../../sdk/helpers/duration';
import { loadERC20 } from '../../sdk/helpers/loadERC20';
import { StakingInterface } from '../../types/dto/Project.dto';
import { PoolVersions } from '../../utils/helpers';
import { RewardsPoolBase as RewardsPoolBaseV2 } from '../../sdk/staking-v2/RewardsPoolBase';
import { NonCompoundingRewardsPool } from '../../sdk/staking-v2/NonCompoundingRewardsPool';

export async function loadV2(pool: StakingInterface): Promise<Pool> {
  const poolContract = new RewardsPoolBaseV2();
  await poolContract.load(pool.campaignAddress);

  const nonCompoundingContract = new NonCompoundingRewardsPool();
  await nonCompoundingContract.load(pool.campaignAddress);

  const promises: Promise<any>[] = [
    poolContract.getStartTimestamp(),
    poolContract.getEndTimestamp(),
    poolContract.getRewardTokens(),
    poolContract.getStakingToken(),
    poolContract.getContractStakeLimit(),
    poolContract.getStakeLimit(),
    nonCompoundingContract.getThrottleRoundDuration(),
    nonCompoundingContract.getThrottleRoundCap(),
    poolContract.getName(),
    poolContract.getTotalStaked(),
  ];
  const results = await Promise.all(promises);

  const tokenContract = loadERC20(results[3]);
  let rewardPerSecond: BigNumber = BigNumber.from('0');
  const startTimestamp = results[0];

  // Check for campaign scheduled
  if (startTimestamp.toNumber() !== 0) {
    rewardPerSecond = await poolContract.getRewardPerSecond(0);
  }
  const startDate = new Date(results[0] * 1000);
  const endDate = new Date(results[1] * 1000);
  const duration = createDuration(endDate.getTime() - startDate.getTime(), DurationUnits.milliseconds);

  const totalReward = rewardPerSecond.mul(durationToUnit(duration, DurationUnits.seconds));
  const secondsPerWeek = 604800;
  const campaignMessage = pool.campaignMessage || '';
  const symbol = await tokenContract.getSymbol();
  const decimals = await tokenContract.getDecimals();
  let excessRewards: BigNumber = BigNumber.from('0');
  try {
    excessRewards = await poolContract.getAvailableBalance(0);
  } catch (error) {}
  const rewardAddress = results[2][0] || tokenContract.contract?.address;

  const rewardContract = loadERC20(rewardAddress);
  const rewardDecimals = await rewardContract.getDecimals();
  const rewardSymbol = await rewardContract.getSymbol();

  const weeklyReward = rewardPerSecond.mul(secondsPerWeek);

  return {
    address: pool.campaignAddress,
    symbol,
    decimals,
    rewardAddress: rewardAddress || pool.campaignAddress,
    rewardDecimals: rewardDecimals,
    rewardSymbol: rewardSymbol,
    rewardExcess: excessRewards,
    totalReward: totalReward,
    totalLimit: results[4],
    walletLimit: results[5],
    weeklyReward,
    duration,
    start: startDate,
    end: endDate,
    done: Date.now() > endDate.getTime(),
    version: pool.version === PoolVersions.v2 ? PoolVersions.v2 : PoolVersions.v3,
    name: results[8],
    campaignMessage: campaignMessage,
    throttleRoundDuration: results[6],
    throttleRoundCap: results[7],
    stakingTokenAddress: results[3],
    totalStaked: results[9],
  };
}
