import { Dispatch } from 'redux';
import { Web3ProviderState } from '../ducks/ethers/web3/slice';
import { addSnackbar } from '../ducks/snackbar/action';

function sleep(time: number): Promise<void> {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export async function getSignature(
  web3: Web3ProviderState,
  dispatch: Dispatch,
): Promise<{ signature: string; timestamp: number }> {
  for (let retries = 0; retries < 5; retries++) {
    try {
      const timestamp = Date.now();
      const signature = await web3?.signer.signMessage(`Verification message: ${timestamp.toString()}`);

      if (Date.now() > timestamp + 120 * 1000) {
        dispatch(
          addSnackbar({
            type: 'error',
            title: 'Signature expired',
            content:
              'The signature is expired because you waited longer then 2 minutes to sign it. Retrying automatically in 5 seconds, please wait...',
          }),
        );
        await sleep(5000);
      } else if (signature) {
        return { signature, timestamp };
      }
    } catch (e) {}
  }

  throw new Error('Failed to sign message');
}
