import React, { useEffect, useState } from 'react';
import { Icons } from '../../assets/icons';
import { BigNumber, constants } from 'ethers';
import { Button } from '../Button';
import { formatNumber, formatTokens } from '../../utils/formatNumber';
import { durationToUnit, DurationUnits, Duration, createDuration } from '../../sdk/helpers/duration';
import { PoolExpiration } from '../PoolExpiration';
import { PoolWhitelist } from '../PoolWhitelist';
import { PoolCampaign } from '../PoolCampaign';
import { PoolAsset } from '../PoolAsset';
import { Popover } from '../Popover';
import { StakingInterface } from '../../types/dto/Project.dto';
import { PoolCampaignMessage } from '../PoolCampaignMessage';
import { RewardsPoolBase } from '../../sdk/staking-v2/RewardsPoolBase';
import { refundCredit } from '../../sdk/payment';
import { useHistory } from 'react-router-dom';
import { formatDuration } from '../../utils/formatDuration';
import { handleError } from '../../utils/handleError';
import { enabledExcessRewardsCampaigns } from '../../utils/staticAddresses';
import { useDispatch, useSelector } from 'react-redux';
import { getEthersWeb3Provider } from '../../ducks/ethers/web3/selectors';
import { PoolVersion, PoolVersions, getExplorerAddressByChainId } from '../../utils/helpers';
import { getNetworkId } from '../../sdk/helpers/network';
import { integerRegex } from '../../sdk/constants/regex';
import { calculatePercentage } from '../../sdk/helpers/calculatePercentage';
import { useAccount, useNetwork } from 'wagmi';

export interface Pool {
  address: string;
  symbol: string;
  decimals: number;
  rewardAddress: string;
  totalReward: BigNumber;
  weeklyReward: BigNumber;
  totalLimit: BigNumber;
  walletLimit: BigNumber;
  duration: Duration;
  rewardExcess: BigNumber;
  rewardDecimals: number;
  rewardSymbol: string;
  start: Date;
  end: Date;
  done: boolean;
  version: PoolVersion;
  name: string;
  campaignMessage: string;
  throttleRoundDuration: Duration;
  throttleRoundCap: BigNumber;
  stakingTokenAddress: string;
  totalStaked?: BigNumber;
}

export const StakingPool: React.FC<{ pool: Pool }> = ({ pool }) => {
  const { address: wallet } = useAccount();
  const { chain } = useNetwork();

  const history = useHistory();
  const dispatch = useDispatch();

  const web3 = useSelector(getEthersWeb3Provider);

  const [whitelistPopup, setWhitelistPopup] = useState<boolean>(false);
  const [chainId, setChainId] = useState<string>('');

  useEffect(() => {
    if (chain?.id) {
      setChainId(String(chain.id));
    }
    return (): void => {
      setChainId('');
    };
  }, [chain?.id]);

  async function cancelStart(): Promise<void> {
    if (pool.version === PoolVersions.v2) {
      const rewardsPool = new RewardsPoolBase();
      await rewardsPool.load(pool.address);

      await handleError(
        rewardsPool.cancel(),
        dispatch,
        'Cancel start',
        'The action is now awaiting multisig confirmation',
        () =>
          setTimeout(() => {
            history.push('/multisig/transactions');
          }, 1000),
      );
    } else {
      await handleError(
        refundCredit(pool.address),
        dispatch,
        'Cancel start',
        'The action is now awaiting multisig confirmation',
        () =>
          setTimeout(() => {
            history.push('/multisig/transactions');
          }, 1000),
      );
    }
  }

  async function withdrawExcessRewards(): Promise<void> {
    const rewardsPool = new RewardsPoolBase();
    await rewardsPool.load(pool.address);

    await handleError(
      rewardsPool.withdrawExcessRewards(wallet),
      dispatch,
      'Withdraw of excess rewards',
      'The action is now awaiting multisig confirmation',
      () =>
        setTimeout(() => {
          history.push('/multisig/transactions');
        }, 1000),
    );
  }

  async function schedule(): Promise<void> {
    let tmpThrottleRoundDuration: Duration;

    const state = {
      step: 1,
      version: pool.version,
      name: pool.name,
      campaignMessage: pool.campaignMessage,
      protocol: 'uniswap',
      stakingAddress: pool.rewardAddress,
      userStakingLimit: { amount: pool.walletLimit.div(BigNumber.from(10).pow(18)), decimals: 18 },
      stakingLimit: { amount: pool.totalLimit.div(BigNumber.from(10).pow(18)), decimals: 18 },
      rewards: [
        {
          readOnly: true,
          info: {
            network: '',
            name: pool.symbol,
            symbol: pool.symbol,
            address: pool.rewardAddress,
            decimals: 18,
            coinGeckoID: '',
            projectToken: true,
          },
          reward: { amount: BigNumber.from(1), decimals: 18 },
        },
      ],
      throttleRoundDuration: formatDuration(pool.throttleRoundDuration, true),
      throttleRoundCap: { amount: pool.throttleRoundCap.div(BigNumber.from(10).pow(18)), decimals: 18 },
      poolAddress: pool.address,
    };

    history.push(`/staking/new`, state);
  }

  const checkMaxStakingLimit = (limit: BigNumber): boolean => {
    const tenBN = BigNumber.from(10);
    const tenPow18BN = tenBN.pow(18);
    const maxAmount = constants.MaxUint256.div(tenPow18BN);

    return limit.div(tenPow18BN).eq(maxAmount);
  };

  const hasContractStakeLimit = !checkMaxStakingLimit(pool.totalLimit);

  // calculate total cooldown duration based on total staked
  let totalCooldownDuration = 0;

  // calculate total cooldown duration based on max limit
  let maxTotalCooldownDuration = 0;

  if (pool.version === PoolVersions.v2) {
    const roundCap = pool.throttleRoundCap.div(BigNumber.from(10).pow(18));
    const roundDuration = durationToUnit(pool.throttleRoundDuration, DurationUnits.days);

    if (pool.totalStaked && integerRegex.test(pool.totalStaked.toString())) {
      const totalStaked = BigNumber.from(pool.totalStaked).div(BigNumber.from(10).pow(18));

      if (roundCap.gt(totalStaked)) {
        totalCooldownDuration = roundDuration;
      } else {
        if (integerRegex.test(roundCap.toString()) && roundCap.gt(0)) {
          totalCooldownDuration = Number(totalStaked.div(roundCap)) * roundDuration;
        } else {
          totalCooldownDuration = (Number(totalStaked) / parseFloat(roundCap.toString())) * roundDuration;
        }
      }
    }

    if (roundCap.gt(pool.totalLimit.div(BigNumber.from(10).pow(18)))) {
      maxTotalCooldownDuration = roundDuration;
    } else {
      if (integerRegex.test(roundCap.toString()) && roundCap.gt(0)) {
        maxTotalCooldownDuration =
          Number(pool.totalLimit.div(BigNumber.from(10).pow(18)).div(roundCap)) * roundDuration;
      } else {
        maxTotalCooldownDuration =
          (Number(pool.totalLimit.div(BigNumber.from(10).pow(18))) / parseFloat(roundCap.toString())) * roundDuration;
      }
    }
  }

  return (
    <div className="staking-pool">
      <div className="pool-header flex flex-center">
        <div className="pool-header-content flex">
          <div className="pool-header-assets flex">
            <div className="pool-asset-exchange flex">
              <img src={`${process.env.REACT_APP_ASSETS}/${pool.rewardAddress.toLocaleLowerCase()}`} />
              {pool.symbol.toUpperCase()}
            </div>
          </div>
        </div>
      </div>

      <div className="pool-content">
        <div className="pool-info">
          {pool.version !== '2.0' && pool.name && <div className="pool-name">{pool.name}</div>}
          {pool.version !== '2.0' && <PoolCampaignMessage pool={pool} />}
          <div className="pool-info-stats flex">
            {pool.start.getTime() === 0 && (
              <div>
                <div className="pool-content-header">Exit round duration</div>
                <div className="pool-exit-round-duration flex">{formatDuration(pool.throttleRoundDuration)}</div>

                <div className="pool-content-header">Exit round limit</div>
                <PoolAsset
                  asset={{
                    balance: pool.throttleRoundCap,
                    symbol: pool.symbol,
                    image: Icons[pool.symbol.toLowerCase()],
                    address: pool.rewardAddress,
                    decimals: pool.decimals,
                  }}
                />
              </div>
            )}

            {pool.start.getTime() !== 0 && (
              <div>
                <div className="pool-content-header">Total reward</div>
                <PoolAsset
                  asset={{
                    balance: pool.totalReward,
                    symbol: pool.rewardSymbol,
                    image: Icons[pool.rewardSymbol.toLowerCase()],
                    address: pool.rewardAddress,
                    decimals: pool.decimals,
                  }}
                />
                <div className="spacer"></div>
              </div>
            )}

            {(pool.version === PoolVersions.v2 || pool.version === PoolVersions.v3) &&
              Number(pool.rewardExcess) / 10 ** pool.rewardDecimals >= 1 && (
                <React.Fragment>
                  <div className="spacer"></div>
                  <div className="pool-assets-treasuries">
                    <div className="pool-assets-treasuries-text flex pool-content-header">Excess rewards</div>
                    <PoolAsset
                      asset={{
                        address: pool.rewardAddress,
                        balance: pool.rewardExcess,
                        symbol: pool.rewardSymbol,
                        decimals: pool.rewardDecimals,
                      }}
                    />
                    {enabledExcessRewardsCampaigns.includes(pool.address.toLowerCase()) && (
                      <Button
                        color="primary-empty"
                        size="small"
                        onClick={(): void => {
                          withdrawExcessRewards();
                        }}
                        label="Withdraw excess rewards"
                      />
                    )}
                  </div>
                </React.Fragment>
              )}

            <div className="spacer"></div>

            <div className="pool-info-limit">
              <div className="pool-content-header">Total limit</div>
              <PoolAsset
                asset={{
                  balance: pool.totalLimit,
                  symbol: pool.symbol,
                  image: Icons[pool.symbol.toLowerCase()],
                  address: pool.stakingTokenAddress,
                  decimals: pool.decimals,
                }}
              />

              <div className="pool-content-header">Per wallet limit</div>
              <PoolAsset
                asset={{
                  balance: pool.walletLimit,
                  symbol: pool.symbol,
                  image: Icons[pool.symbol.toLowerCase()],
                  address: pool.stakingTokenAddress,
                  decimals: pool.decimals,
                }}
              />
            </div>
            {hasContractStakeLimit && (
              <div className="percentage-vertical-bar">
                <div
                  className={`${
                    calculatePercentage(
                      BigNumber.from(
                        pool.totalStaked && integerRegex.test(pool.totalStaked.toString()) ? pool.totalStaked : 0,
                      ),
                      BigNumber.from(pool.totalLimit),
                    ) !== 100
                      ? 'percentage-progress-max'
                      : ''
                  } percentage-progress`}
                  style={{
                    height: `${calculatePercentage(
                      BigNumber.from(
                        pool.totalStaked && integerRegex.test(pool.totalStaked.toString()) ? pool.totalStaked : 0,
                      ),
                      BigNumber.from(pool.totalLimit),
                    ).toString()}%`,
                  }}
                />
              </div>
            )}
          </div>

          {pool.start.getTime() !== 0 && (
            <>
              <div className="pool-info-divider"></div>
              <div className="pool-info-locking">
                <PoolExpiration end={pool.end} start={pool.start} />
                <div className="spacer"></div>

                <div className="pool-info-lockup">
                  {pool.version === PoolVersions.v2 && hasContractStakeLimit && (
                    <>
                      <div className="pool-content-header">
                        Max Cooldown Duration{' '}
                        <Popover
                          title="Max Cooldown Duration"
                          content={
                            <>
                              This is the total amount of time during which the staked tokens will be unlocked
                              continuously <strong>if the campaigns is full based on the total limit</strong>.
                              Withdrawal of the tokens is on a first-come, first-served basis. Users who withdraw early
                              will be able to claim their tokens earlier.
                            </>
                          }
                        >
                          <img className="popover-info" src={Icons.info} />
                        </Popover>
                      </div>
                      <div className="pool-info-lockup-duration">
                        {maxTotalCooldownDuration < 1
                          ? `${formatNumber(Math.ceil(maxTotalCooldownDuration * 24))} hour(s)`
                          : `${formatNumber(Math.ceil(maxTotalCooldownDuration))} day(s)`}
                      </div>
                    </>
                  )}
                  {pool.version === PoolVersions.v2 && (
                    <>
                      <div className="pool-content-header">
                        Actual Cooldown Duration{' '}
                        <Popover
                          title="Actual Cooldown Duration"
                          content={
                            <>
                              This is the total amount of time during which the staked tokens will be unlocked
                              continuously <strong>based on the actual total staked amount</strong>. Withdrawal of the
                              tokens is on a first-come, first-served basis. Users who withdraw early will be able to
                              claim their tokens earlier.
                            </>
                          }
                        >
                          <img className="popover-info" src={Icons.info} />
                        </Popover>
                      </div>
                      <div className="pool-info-lockup-duration">
                        {totalCooldownDuration < 1
                          ? `${formatNumber(Math.ceil(totalCooldownDuration * 24))} hour(s)`
                          : `${formatNumber(Math.ceil(totalCooldownDuration))} day(s)`}
                      </div>
                    </>
                  )}
                </div>

                <div className="spacer"></div>
                <div className="pool-info-lockup total-staked">
                  <div className="pool-content-header">Total Staked</div>
                  <PoolAsset
                    asset={{
                      balance: pool && pool.totalStaked ? pool.totalStaked : BigNumber.from(0),
                      decimals: pool.decimals,
                      symbol: pool.symbol,
                      image: Icons[pool.symbol.toLowerCase()],
                      address: pool.stakingTokenAddress,
                    }}
                  />
                </div>
              </div>
            </>
          )}

          <div className="pool-version">V{pool.version == '3.0' ? '2.0' : pool.version}</div>
          <div className="pool-address">
            {pool.address}{' '}
            <a
              href={`${getExplorerAddressByChainId(chainId)}${pool.address}`}
              className="hyperlink-pool-address"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Icons.open_link_light} />
            </a>
          </div>
        </div>

        <PoolCampaign start={pool.start} end={pool.end}>
          {(pool.version === PoolVersions.v2 || pool.version === PoolVersions.v3) && pool.start > new Date() && (
            <Button
              color="primary-empty"
              size="medium"
              onClick={(): void => {
                cancelStart();
              }}
              label="Cancel start"
              icon="close"
              iconposition="left"
            />
          )}

          {pool.start.getTime() === 0 && (
            <>
              <Button
                color="primary-empty"
                size="small"
                onClick={(): void => {
                  withdrawExcessRewards();
                }}
                label="Withdraw excess rewards"
                icon="left_arrow"
                iconposition="left"
              />
              <Button
                color="primary-empty"
                size="medium"
                onClick={schedule}
                label="Schedule start"
                icon="calendar"
                iconposition="left"
              />
            </>
          )}

          {pool.start.getTime() !== 0 && pool.start < new Date() && (
            <Button
              color="primary-empty"
              size="medium"
              onClick={(): void => {
                setWhitelistPopup(true);
              }}
              label="Whitelist pool"
              icon="pool_blue"
              iconposition="left"
            />
          )}
        </PoolCampaign>
      </div>

      {whitelistPopup && (
        <PoolWhitelist
          pool={pool}
          onClose={(wasWhitelisted: boolean): void => {
            setWhitelistPopup(false);
            if (wasWhitelisted) {
              history.push('/multisig/transactions');
            }
          }}
        />
      )}
    </div>
  );
};
