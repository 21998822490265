import { Balancer } from '../dex/Balancer';
import { Uniswap } from '../dex/Uniswap';
import { Pancakeswap } from '../dex/Pancakeswap';
import { Arrakis } from '../dex/Arrakis';

export const getProtocolContract = async (protocol: string, address: string): Promise<any> => {
  let poolTokenContract;

  if (protocol === 'balancer') {
    poolTokenContract = new Balancer();
  } else if (protocol === 'arrakis') {
    poolTokenContract = new Arrakis();
  } else if (
    protocol === 'uniswap' ||
    protocol === 'pancakeswap' ||
    protocol === 'pangolin' ||
    protocol === 'quickswap' ||
    protocol === 'alliancedex' ||
    protocol === 'solarflare' ||
    protocol === 'traderjoe'
  ) {
    poolTokenContract = new Uniswap();
  } else {
    throw new Error("Unknown protocol '" + protocol + "'");
  }

  await poolTokenContract.load(address);

  return poolTokenContract;
};
