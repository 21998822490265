import React, { FC, Suspense } from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from '../ducks/store';
import { Header } from '../containers/Header';
import { Fonts } from '../fonts';
import { Main } from '../containers/Main';
import { PrivateRoute } from '../containers/routes/PrivateRoute';
import { Transactions } from './Manage/Transactions';
import { Admins } from './Manage/Admins';
import { Onboarding } from './Onboarding';
import { MultisigCreation } from '../containers/MultisigCreation';
import { AuthorizationRoute } from '../containers/routes/AuthorizationRoute';
import { Brand } from './Configuration/Brand';
import { Theme } from './Configuration/Theme';
import { Colors } from './Configuration/Colors';
import { ContactEmail } from './Configuration/ContactEmail';
import { SocialMedia } from './Configuration/SocialMedia';
import { ThirdPartyIntegrations } from './Configuration/ThirdPartyIntegrations';
import { CongratulationsScreen } from './Configuration/CongratulationsScreen';
import { Overview } from './Metrics/Overview';
import { DataTable } from './Metrics/DataTable';
import { Snackbars } from '../containers/Snackbars';
import { Tokens } from './Configuration/Tokens';
import { Payment } from './Manage/Payment';
import { Staking } from './Staking';
import { LiquidityMining } from './LiquidityMining';
import { DAppsIntegration } from './Configuration/DAppsIntegration';

export const App: FC = () => {
  return (
    <Suspense fallback={null}>
      <Fonts />
      <BrowserRouter>
        <Provider store={store}>
          <Header />
          <MultisigCreation />
          <Snackbars />
          <Switch>
            {/* ONBOARDING */}
            <AuthorizationRoute path="/register">
              <Onboarding />
            </AuthorizationRoute>
            {/* ELSE */}
            {/* <Route path="/public" >*/}
            {/* Private Routes */}
            <Main>
              {/* STAKING */}
              <Staking baseUrl="/staking" />
              {/* MULTISIG */}
              <PrivateRoute path="/multisig" redirectTo="/login">
                <Switch>
                  <Route path="/multisig/transactions">
                    <Transactions />
                  </Route>
                  <Route path="/multisig/admins">
                    <Admins />
                  </Route>
                  <Redirect to="/multisig/admins" />
                </Switch>
              </PrivateRoute>
              {/* POOLS */}
              <LiquidityMining baseUrl="/pools" />
              {/* CONFIGURATION */}
              <PrivateRoute path="/configuration" redirectTo="/register">
                <Switch>
                  <Route path="/configuration/brand">
                    <Brand />
                  </Route>
                  <Route path="/configuration/theme">
                    <Theme />
                  </Route>
                  <Route path="/configuration/colors">
                    <Colors />
                  </Route>
                  <Route path="/configuration/contact_email">
                    <ContactEmail />
                  </Route>
                  <Route path="/configuration/third_party_integrations">
                    <ThirdPartyIntegrations />
                  </Route>
                  <Route path="/configuration/social_media">
                    <SocialMedia />
                  </Route>
                  <Route path="/configuration/tokens">
                    <Tokens />
                  </Route>
                  {/* <Route path="/configuration/dapps_integration">
                    <DAppsIntegration />
                  </Route> */}
                  <Route path="/configuration/congratulations">
                    <CongratulationsScreen />
                  </Route>
                  <Redirect to="/configuration/brand" />
                </Switch>
              </PrivateRoute>
              {/* METRICS */}
              <PrivateRoute path="/metrics" redirectTo="/register">
                <Switch>
                  <Route path="/metrics/overview">
                    <Overview />
                  </Route>
                  <Route path="/metrics/data-table">
                    <DataTable />
                  </Route>
                  <Redirect to="/metrics/overview" />
                </Switch>
              </PrivateRoute>
              {/* PAYMENT */}
              <PrivateRoute path="/credits" redirectTo="/register">
                <Switch>
                  <Route path="/credits/payment">
                    <Payment />
                  </Route>
                  <Redirect to="/credits/payment" />
                </Switch>
              </PrivateRoute>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/metrics" />
                </Route>
              </Switch>
            </Main>
          </Switch>
        </Provider>
      </BrowserRouter>
    </Suspense>
  );
};
