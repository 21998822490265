import React from 'react';
import { Pool } from '../../../components/LiquidityMiningPool';
import { LiquidityMiningPoolList } from '../../../components/LiquidityMiningPoolList';

export const ExpiredPools: React.FC = () => {
  return (
    <LiquidityMiningPoolList
      title="Expired pools"
      subTitle="List of expired pools"
      filter={(pool: Pool): boolean => {
        return pool.start.getTime() !== 0 && pool.end < new Date();
      }}
    />
  );
};
